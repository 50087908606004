<template>
  <div>
    <v-row>
      <v-col cols="6" offset="3">
        <br />
        <v-alert border="right" colored-border type="info" elevation="2"> Upload S3 </v-alert>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
        <v-file-input :prepend-icon="null" accept=".pdf" show-size prepend-inner-icon="mdi-file-upload" outlined multiple dense hide-details label="Termo de Abertura" id="abertura" v-model="Files"></v-file-input>
      </v-col>
    </v-row>
    <v-row>
      <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
        <v-file-input :prepend-icon="null" accept=".pdf" show-size prepend-inner-icon="mdi-file-upload" outlined multiple dense hide-details label="Termo de Fechamento" id="fechamento" v-model="Files"></v-file-input>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col col="12" md="12" lg="8" offset="0" offset-md="2" offset-lg="2">
        <v-row>
          <v-col> </v-col>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-col> </v-col>
          <v-col>
            <v-btn block @click="UploadFile()" color="primary">Salvar</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UploadS3",
  data() {
    return {
      Files: [],
    };
  },
  methods: {
    RemoveFile() {
      axios({ method: "delete", data: this.ArquivoParaRemover, url: "/api/Estoque/Recebimento/DeleteArquivo" })
        .then((response) => {
          this.Model.Arquivos.splice(this.Model.Arquivos.indexOf(this.ArquivoParaRemover), 1);
        })
        .catch((error) => {
          //
        });
    },
    DownloadFile(Arquivo) {
      axios({ method: "post", responseType: "blob", data: Arquivo, url: "/api/File/Download" }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", Arquivo.Nome);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    UploadFile() {
      axios({ method: "post", data: this.Files, url: "/api/UploadS3/Upload", headers: { "Content-type": "multipart/form-data" } })
        .then((response) => {
          console.log("Upload OK.");
        })
        .catch((error) => {
          console.log("Upload Error.");
        });
    },
  },
};
</script>
