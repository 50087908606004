<template>
  <v-container fill-height fluid>
    <v-row class="grow" align="center" justify="center">
      <v-col lg="3" md="4" sm="7" xs="12">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title> Sistema ATA - Reuperação de Senha </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-form>
              <template v-if="AlteracaoDeSenha">
                <v-row>
                  <v-col cols="12">
                    <v-text-field outlined dense hide-details label="Nova Senha" prepend-inner-icon="mdi-key" color="warning" :type="'password'" v-model="usuario.NovaSenha" autocomplete="off"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field outlined dense hide-details label="Confirmar Nova Senha" prepend-inner-icon="mdi-key" color="success" :type="'password'" v-model="usuario.ConfirmaSenha" autocomplete="off"></v-text-field>
                  </v-col>
                </v-row>
              </template>
              <template v-else>
                <v-row>
                  <v-col></v-col>
                  <v-col align="center">
                    <v-icon size="128" color="#4f4f4f">mdi-book-check-outline</v-icon>
                  </v-col>
                  <v-col></v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-text-field outlined dense hide-details label="E-Mail" name="email" ref="email" prepend-inner-icon="mdi-email-outline" type="text" v-model="usuario.Email" @keyup.native.enter="Recuperar" />
                  </v-col>
                </v-row>
              </template>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <router-link to="/Login"><v-icon color="primary" size="23">mdi-account-circle-outline</v-icon> Página de Login </router-link>
            <v-spacer />
            <v-btn outlined color="primary" dark @click="Recuperar()">Recuperar Senha</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="grow" align="center" justify="center" v-if="MostraMensagem">
      <v-col lg="3" md="4" sm="7" xs="12">
        <v-alert outlined type="success" text> {{ Mensagem }} </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "RecuperarSenha",
  data() {
    return {
      usuario: {
        Email: "",
        NovaSenha: "",
        ConfirmaSenha: "",
        ChaveResetSenha: ""
      },
      Mensagem: "",
      MostraMensagem: false,
      AlteracaoDeSenha: false,
    };
  },
  methods: {
    Recuperar() {
      axios({
        method: "post",
        url: "/api/Sistema/Usuarios/RecuperarSenha",
        data: this.usuario,
      })
        .then((response) => {
          if(this.AlteracaoDeSenha)
          {
            this.usuario.ChaveResetSenha = null;
            this.usuario.NovaSenha = null;
            this.usuario.ConfirmaSenha = null;
            this.Mensagem = "Senha alterada com sucesso. Volte para a página de Login."
          }else{
            this.Mensagem = "Uma mensagem com link de recuperação foi enviada para o e-mail cadastrado."
          }

          this.MostraMensagem = true;
        })
        .catch((error) => {
          this.emailFocus();
        });
    },
    emailFocus() {
      this.$refs.email.focus();
    },
  },
  mounted: function () {
    let id = this.$route.params.id;
    if (id != null) {
      this.AlteracaoDeSenha = true;
      this.usuario.ChaveResetSenha = id;
    } else {
      this.AlteracaoDeSenha = false;
      this.usuario.ChaveResetSenha = null;
      this.emailFocus();
      this.MostraMensagem = false;
    }
  },
};
</script>
