import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Login from '../views/Login.vue'
import RecuperarSenha from '../views/RecuperarSenha.vue'
import Logout from '../views/Logout.vue'
import Denied from '../views/Denied.vue'
import Error from '../views/Error.vue'
import NotFound from '../views/NotFound.vue'
import AtaIndex from '../views/Ata/AtaIndex.vue'
import AtaEdit from '../views/Ata/AtaEdit.vue'
import AtaDownload from '../views/Ata/Download.vue'
import EmpresaIndex from '../views/Cadastro/Empresa/EmpresaIndex.vue'
import EmpresaEdit from '../views/Cadastro/Empresa/EmpresaEdit.vue'
import AtaTipoIndex from '../views/Cadastro/AtaTipo/AtaTipoIndex.vue'
import AtaTipoEdit from '../views/Cadastro/AtaTipo/AtaTipoEdit.vue'
import ConfiguracoesIndex from '../views/Cadastro/Configuracoes/ConfiguracoesIndex.vue'
import ConfiguracoesEdit from '../views/Cadastro/Configuracoes/ConfiguracoesEdit.vue'
import StatusIndex from '../views/Cadastro/Status/StatusIndex.vue'
import StatusEdit from '../views/Cadastro/Status/StatusEdit.vue'

//Controllers
import SistemaControllerIndex from '../views/Sistema/Controller/SistemaControllerIndex.vue'
import SistemaControllerEdit from '../views/Sistema/Controller/SistemaControllerEdit.vue'

//Grupos
import SistemaGrupoIndex from '../views/Sistema/Grupo/SistemaGrupoIndex.vue'
import SistemaGrupoEdit from '../views/Sistema/Grupo/SistemaGrupoEdit.vue'

import SistemaGrupoUsuariosIndex from "../views/Sistema/GrupoUsuarios/SistemaGrupoUsuariosIndex.vue";
import SistemaGrupoUsuariosEdit from "../views/Sistema/GrupoUsuarios/SistemaGrupoUsuariosEdit.vue";

import SistemaGrupoControllerIndex from "../views/Sistema/GrupoController/SistemaGrupoControllerIndex.vue";
import SistemaGrupoControllerEdit from "../views/Sistema/GrupoController/SistemaGrupoControllerEdit.vue";

//Usuários
import SistemaUsuarioIndex from "../views/Sistema/Usuario/SistemaUsuarioIndex.vue";
import SistemaUsuarioEdit from "../views/Sistema/Usuario/SistemaUsuarioEdit.vue";

import SistemaAcessoIndex from "../views/Cadastro/Acesso/SistemaAcessoIndex.vue";
import SistemaAcessoEdit from "../views/Cadastro/Acesso/SistemaAcessoEdit.vue";

//Controle de Menu
import SistemaMenuIndex from '../views/Sistema/Menu/SistemaMenuIndex.vue'
import SistemaMenuEdit from '../views/Sistema/Menu/SistemaMenuEdit.vue'

//Permissões
import SistemaPermissaoIndex from '../views/Sistema/Permissao/SistemaPermissaoIndex.vue'
import SistemaPermissaoEdit from '../views/Sistema/Permissao/SistemaPermissaoEdit.vue'

import UploadS3 from '../views/Ata/UploadS3.vue'

Vue.use(VueRouter)

const TituloPadrao = 'FCM Legal - ';

const routes = [
    //Home do Sistema
    { path: "/", redirect: "/home", meta: { title: TituloPadrao.concat("Home") } },
    { path: "/home", name: "home", component: Home, meta: { title: TituloPadrao.concat("Home") } },

    //Empresa
    { path: "/Cadastro/Empresa/Index", name: "EmpresaIndex", component: EmpresaIndex, meta: { title: TituloPadrao.concat("Empresa Index") } },
    { path: "/Cadastro/Empresa/Edit/:id", name: "EmpresaEdit", component: EmpresaEdit, meta: { title: TituloPadrao.concat("Empresa Edit") } },

    //Atas
    { path: "/Ata/Index", name: "AtaIndex", component: AtaIndex, meta: { title: TituloPadrao.concat("Atas Index") } },
    { path: "/Ata/Edit/:id", name: "AtaEdit", component: AtaEdit, meta: { title: TituloPadrao.concat("Ata Edit") } },
    { path: "/Ata/Download/:id", name: "AtaDownload", component: AtaDownload, meta: { title: TituloPadrao.concat("Ata Download") } },

    //Tipo de Ata
    { path: "/Cadastro/AtaTipo/Index", name: "AtaTipoIndex", component: AtaTipoIndex, meta: { title: TituloPadrao.concat("Tipos de Ata Index") } },
    { path: "/Cadastro/AtaTipo/Edit/:id", name: "AtaTipoEdit", component: AtaTipoEdit, meta: { title: TituloPadrao.concat("Tipo de Ata Edit") } },

    //Consifurações do Sistema
    { path: "/Cadastro/Configuracoes/Edit/:id", name: "ConfiguracoesoEdit", component: ConfiguracoesEdit, meta: { title: TituloPadrao.concat("Configurações") } },
    { path: "/Cadastro/Configuracoes/Index", name: "ConfiguracoesIndex", component: ConfiguracoesIndex, meta: { title: TituloPadrao.concat("Configurações") } },

    //Cadastro das Cotnrollers
    { path: "/Sistema/Controllers/Index", name: "SistemaControllerIndex", component: SistemaControllerIndex, meta: { title: TituloPadrao.concat("Controllers Index") } },
    { path: "/Sistema/Controllers/Edit/:id", name: "SistemaControllerEdit", component: SistemaControllerEdit, meta: { title: TituloPadrao.concat("Controller Edit") } },

    //Usuarios
    { path: "/Sistema/Usuarios/Index", name: "SistemaUsuarioIndex", component: SistemaUsuarioIndex, meta: { title: TituloPadrao.concat("Usuários Index") } },
    { path: "/sistema/Usuarios/Edit/:id", name: "SistemaUsuarioEdit", component: SistemaUsuarioEdit, meta: { title: TituloPadrao.concat("Usuário Edit") } },

    { path: "/Cadastro/Acessos/Index", name: "SistemaAcessoIndex", component: SistemaAcessoIndex, meta: { title: TituloPadrao.concat("Acessos Index") } },
    { path: "/Cadastro/Acessos/Edit/:id", name: "SistemaAcessoEdit", component: SistemaAcessoEdit, meta: { title: TituloPadrao.concat("Acesos Edit") } },

    //Grupos
    { path: "/Sistema/Grupos/Index", name: "SistemaGrupoIndex", component: SistemaGrupoIndex, meta: { title: TituloPadrao.concat("Grupos Index") } },
    { path: "/sistema/Grupos/Edit/:id", name: "SistemaGrupoEdit", component: SistemaGrupoEdit, meta: { title: TituloPadrao.concat("Grupos Edit") } },

    { path: "/Sistema/GruposUsuarios/Index", name: "SistemaGrupoUsuariosIndex", component: SistemaGrupoUsuariosIndex, meta: { title: TituloPadrao.concat("Grupos de Usuários Index") } },
    { path: "/sistema/GruposUsuarios/Edit/:id", name: "SistemaGrupoUsuariosEdit", component: SistemaGrupoUsuariosEdit, meta: { title: TituloPadrao.concat("Grupo de Usuários Edit") } },

    { path: "/Sistema/GruposController/Index", name: "SistemaGrupoControllerIndex", component: SistemaGrupoControllerIndex, meta: { title: TituloPadrao.concat("Grupos de Controllers Index") } },
    { path: "/sistema/GruposController/Edit/:id", name: "SistemaGrupoControllerEdit", component: SistemaGrupoControllerEdit, meta: { title: TituloPadrao.concat("Grupo de Controller Edit") } },

    //Controle de Menu
    { path: "/Sistema/Menus/Index", name: "SistemaMenuIndex", component: SistemaMenuIndex, meta: { title: TituloPadrao.concat("Controle de Menus Index") } },
    { path: "/sistema/Menus/Edit/:id", name: "SistemaMenuEdit", component: SistemaMenuEdit, meta: { title: TituloPadrao.concat("Controle de Menu Edit") } },

    //Permissões
    { path: "/Sistema/Permissoes/Index", name: "SistemaPermissaoIndex", component: SistemaPermissaoIndex, meta: { title: TituloPadrao.concat("Permissões Index") } },
    { path: "/Sistema/Permissoes/Edit/:id", name: "SistemaPermissaoEdit", component: SistemaPermissaoEdit, meta: { title: TituloPadrao.concat("Permissão Edit") } },

    //Status
    { path: "/Cadastro/Status/Index", name: "StatusIndex", component: StatusIndex, meta: { title: TituloPadrao.concat("Status Index") } },
    { path: "/Cadastro/Status/Edit/:id", name: "StatusEdit", component: StatusEdit, meta: { title: TituloPadrao.concat("Status Index") } },

    //Páginas auxiliares
    { path: "/UploadS3", name: "UploadS3", component: UploadS3, meta: { title: TituloPadrao.concat("Upload-AWS-S3") } },
    { path: "/about", name: "about", component: About, meta: { title: TituloPadrao.concat("Sobre o Sistema") } },
    { path: "/Login", name: "Login", component: Login, meta: { title: TituloPadrao.concat("Login") } },
    { path: "/RecuperarSenha", name: "RecuperarSenha", component: RecuperarSenha, meta: { title: TituloPadrao.concat("Recuperar Senha") } },
    { path: "/RecuperarSenha/:id", name: "RecuperarSenhaId", component: RecuperarSenha, meta: { title: TituloPadrao.concat("Recuperar Senha") } },
    { path: "/Logout", name: "Logout", component: Logout, meta: { title: TituloPadrao.concat("Logout") } },
    { path: "/Denied", name: "Denied", component: Denied, meta: { title: TituloPadrao.concat("Acesso Negado") } },
    { path: "/Error", name: "Error", component: Error, props: true, meta: { title: TituloPadrao.concat("Erro") } },
    { path: "/Error/{mensagem}", name: "ErrorMsg", component: Error, props: true, meta: { title: TituloPadrao.concat("Erro") } },

    //Rota padrão para página não encontrada
    { path: "*", name: "NotFound", component: NotFound, meta: { title: TituloPadrao.concat("Página não encontrada") } },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {

    //Configuração de acesso à rotas públicas e privadas
    //Apenas a página de Login e Logout são públicas
    //Todo o restante requer autenticação no sistema

    //Toda vez que uma rota é acionada [to] receberá o caminho da rota requerida

    const reportPages = [];
    const publicPages = ['Login', 'Logout', 'RecuperarSenha', 'RecuperarSenhaId', 'AtaDownload'];
    const adminPages = [];
    const authRequired = !publicPages.includes(to.name);
    const isReportPage = reportPages.includes(to.name);
    const adminRequired = adminPages.includes(to.name);
    const loggedIn = store.getters.usrLogged;

    store.commit("SET_TITULOTELA", to.meta.title);

    if (!isReportPage)
        store.commit("SET_MODORELATORIO", false);

    if (authRequired && !loggedIn) {
        //Redireciona para o login caso uma rota privada seja chamada
        //sem que o usuário esteja autenticado
        return next('/Login');
    } else {
        next();
    }
})

router.afterEach((to, from) => {
    document.title = to.meta.title || "Arbitragem";
});

export default router