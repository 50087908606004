<template>
  <v-container>
    <v-row>
      <v-col cols="d-flex flex-column">
        <v-card class="mx-auto" width="400" prepend-icon="mdi-home" name="vcardContasPagar">
          <v-card-title> Atas Abertas</v-card-title>
          <v-card-text> </v-card-text>
          <v-card-actions>
            <v-btn color="primary" to="/Ata/Index"> Acessar </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="d-flex flex-column">
        <v-card class="mx-auto" width="400" prepend-icon="mdi-home" name="vcardContasReceber">
          <v-card-title> Atas Fechadas</v-card-title>
          <v-card-text> </v-card-text>
          <v-card-actions>
            <v-btn color="primary" to="/Ata/Index" > Acessar </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="d-flex flex-column">
        <v-card class="mx-auto" width="400" prepend-icon="mdi-home" name="vcardOS">
          <v-card-title> Atas Públicas</v-card-title>
          <v-card-text> </v-card-text>
          <v-card-actions>
            <v-btn color="primary" to="/Ata/Index"> Acessar </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "Home",
  data() {
    return {
      Model: {},
    };
  },
  components: {},

  methods: {},
  mounted: function () {
  },
};
</script>
