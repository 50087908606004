<template>
  <div class="bordaappbar">
    <v-data-table dense class="elevation-1 row-pointer" mobile-breakpoint="1" :headers="Header" :items="GridData" :item-class="RowClass"  :sort-desc.sync="Pagination.sortDesc" item-key="Id" :options.sync="Pagination" :server-items-length="Pagination.itemsLength" :footer-props="{itemsPerPageOptions: RowsPerPageItems }" v-on:update:page="Index()" v-on:update:items-per-page="Index()" v-on:update:sort-by="Index()" v-on:update:sort-desc="Index()">
      <template v-slot:top>
        <v-toolbar dense flat>
          <v-text-field ense append-icon="mdi-magnify" label="Procurar" v-model="Pagination.Filtro.Busca" single-line hide-details @keyup.native.enter="Index()" @click:append="Index" ></v-text-field> &nbsp;&nbsp;
          <v-spacer></v-spacer>
          <v-btn to="/Sistema/Permissoes/Edit/0" color="primary" v-if="CanEdit">Nova <v-icon>mdi-plus</v-icon> </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.Acesso`]="{ item }">
        <template v-if="item.FlagGrupoDeController == true"> <v-icon color="warning">mdi-application-array-outline</v-icon> {{item.GrupoController.Nome}} </template> 
        <template v-else > <v-icon  color="info">mdi-application-braces-outline</v-icon> {{item.Controller.Nome}} </template> 
      </template>

      <template v-slot:[`item.Para`]="{ item }">
        <template v-if="item.FlagPermissaoDeGrupo == true"> <v-icon  color="warning">mdi-account-group</v-icon> {{item.GrupoUsuario.Nome}} </template> 
        <template v-else > <v-icon  color="info">mdi-account</v-icon> {{item.Usuario.Nome}} </template> 
      </template>

      <template v-slot:[`item.Index`]="{ item }">
        <span class="text-no-wrap">
          <v-icon :color="(item.FlagIndex == true)?'success':'error'" >{{(item.FlagIndex == true)?'mdi-check-circle':'mdi-close-circle'}}</v-icon>
        </span>
      </template>

      <template v-slot:[`item.Edit`]="{ item }">
        <span class="text-no-wrap">
          <v-icon :color="(item.FlagEdit == true)?'success':'error'" >{{(item.FlagEdit == true)?'mdi-check-circle':'mdi-close-circle'}}</v-icon>
        </span>
      </template>

      <template v-slot:[`item.Save`]="{ item }">
        <span class="text-no-wrap">
          <v-icon :color="(item.FlagSave == true)?'success':'error'" >{{(item.FlagSave == true)?'mdi-check-circle':'mdi-close-circle'}}</v-icon>
        </span>
      </template>

      <template v-slot:[`item.Delete`]="{ item }">
        <span class="text-no-wrap">
          <v-icon :color="(item.FlagDelete == true)?'success':'error'" >{{(item.FlagDelete == true)?'mdi-check-circle':'mdi-close-circle'}}</v-icon>
        </span>
      </template>

      <template v-slot:[`item.Action`]="{ item }">
        <v-icon @click="Edit(item.Id)" color="teal">mdi-pencil</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Permissoes",
  data() {
    return {
      GridData: [],
      Header: [
        { text: "Id", value: "Id", sortable: false },
        { text: "Controller/Grupo", value: "Acesso", sortable: false },
        { text: "Usuário/Grupo", value: "Para", sortable: false },
        { text: "Index", value: "Index", sortable: false },
        { text: "Edit", value: "Edit", sortable: false },
        { text: "Save", value: "Save", sortable: false },
        { text: "Delete", value: "Delete", sortable: false },
        { text: "", value: "Action", sortable: false },
      ],
      RowsPerPageItems: [5, 10, 20, 30, 40, 50, 100, 200],
      Pagination: {
        page: 1,
        itemsPerPage: 100,
        pageCount: 1,
        itemsLength: 0,
        sortBy: ["Controller.Nome"],
        sortDesc: [false],
        Filtro: {
          Busca: "",
          Inativos: false,
        },
      },
      showDialogCadastro: false,
            IsPermitted: 0,
      CanEdit: false,
      CanDelete: false,
      AllowAll: false,
      HideEdit: false,
      HideDelete: false,
    };
  },
  components: {},
  methods: {
    Edit(Id) {
      this.$router.push("/Sistema/Permissoes/Edit/".concat(Id));
    },
    Index() {
      axios.post("/api/Sistema/Permissoes/Index", this.Pagination).then((response) => {
        this.GridData = response.data.Data;
        this.Pagination = response.data.Pagination;
            this.LoadPermission();

      });
    },
    RowClass(item) {
      if (item.Ativo == false) {
        return "rowdisabled";
      }
    },
    LoadPermission() {
      axios({ method: "get", data: null, url: "/api/Lista/GetAcesso" })
        .then((response) => {
          this.IsPermitted = response.data;
          this.HideEdit = this.$functions.EditPermitted(this.IsPermitted);
          this.CanEdit = Boolean(this.$functions.EditPermitted(this.IsPermitted, 1));
          this.AllowAll = this.$functions.AllowPermitted();
          this.HideDelete = this.$functions.DeletePermitted(this.IsPermitted);
          this.CanDelete = Boolean(this.$functions.DeletePermitted(this.IsPermitted, 1));        })
        .catch((error) => {
          //
        });
    },    
  },
  mounted: function () {
    this.Index();
  },
};
</script>
