<template>
  <div :style="HideEdit">
    <v-card>
      <v-card-text class="pl-1 pr-1">
        <v-row>
          <v-col col="4" md="2" lg="2" offset="0" offset-md="2" offset-lg="2">
            <v-text-field outlined dense hide-details label="Id" v-model="Model.Id" readonly></v-text-field>
          </v-col>
          <v-col>
            <v-switch v-model="Model.FlagPublico" label="Controller Pública" :true-value="1" :false-value="0"></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
            <v-text-field v-model.trim="Model.Nome" label="Controller" dense outlined hide-details readonly></v-text-field>
          </v-col>
        </v-row>

        <v-row dense :style="this.AllowAll">
          <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2" class="mt-10">
            <v-row>
              <v-col>
                <v-btn block @click="ShowConfirmaExcluir = true" color="error" v-if="CanDelete">Excluir Controller</v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-col>
                <v-btn block @click="Index()" color="warning">Cancelar</v-btn>
              </v-col>
              <v-col v-if="SomenteLeitura">
                <v-btn block @click="ShowConfirmaSalvar = true" color="primary" v-if="CanEdit">Salvar</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <confirm Msg="Excluir?" :Show="ShowConfirmaExcluir" v-on:confirm="Delete(), (ShowConfirmaExcluir = false)" v-on:cancel="ShowConfirmaExcluir = false"></confirm>
    <confirm Msg="Salvar?" :Show="ShowConfirmaSalvar" v-on:confirm="Save(), (ShowConfirmaSalvar = false)" v-on:cancel="ShowConfirmaSalvar = false"></confirm>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SistemaControllersEdit",
  data() {
    return {
      ShowDialog: true,
      Model: {},
      ShowConfirmaExcluir: false,
      ShowConfirmaSalvar: false,
      RotaControllers: [],
      IsPermitted: 0,
      CanEdit: false,
      CanDelete: false,
      AllowAll: false,
      HideEdit: false,
      HideDelete: false,
    };
  },
  methods: {
    Edit(Id) {
      axios.get("/api/Sistema/Controllers/Edit/".concat(Id)).then((response) => {
        this.Model = response.data.Data;
      });
    },
    Save() {
      axios({ method: "post", data: this.Model, url: "/api/Sistema/Controllers/Save" })
        .then((response) => {
          this.Index();
          this.$toast.success("Dados Salvos");
        })
        .catch((error) => {
          //
        });
    },
    Delete() {
      axios({ method: "delete", data: this.Model, url: "/api/Sistema/Controllers/Delete" })
        .then((response) => {
          this.Index();
        })
        .catch((error) => {
          //
        });
    },
    Index() {
      this.$router.push("/Sistema/Controllers/Index");
    },
    GetListas() {
      const instance = axios.create();
      //const GetRotaControllers = instance.get("/api/Listas/RotaControllers");
      const GetRotaControllers = instance.get("/api/Lista/GetControllers");

      axios
        .all([GetRotaControllers])
        .then(
          axios.spread((...responses) => {
            responses[0].data.forEach((item) => {
              this.RotaControllers.push(item);
            });
          })
        )
        .catch((errors) => {});
    },
    LoadPermission() {
      axios({ method: "get", data: null, url: "/api/Lista/GetAcesso" })
        .then((response) => {
          this.IsPermitted = response.data;
          this.HideEdit = this.$functions.EditPermitted(this.IsPermitted);
          this.CanEdit = Boolean(this.$functions.EditPermitted(this.IsPermitted, 1));
          this.AllowAll = this.$functions.AllowPermitted();
          this.HideDelete = this.$functions.DeletePermitted(this.IsPermitted);
          this.CanDelete = Boolean(this.$functions.DeletePermitted(this.IsPermitted, 1));
        })
        .catch((error) => {
          //
        });
    },
  },
  computed: {
    SomenteLeitura() {
      if (this.Model.Id != 0) return true;
      else return false;
    },
  },
  mounted: function () {
    let id = this.$route.params.id;
    this.GetListas();

    if (id != null) {
      this.Edit(id);
      this.LoadPermission();
    } else {
      this.$router.push("/Sistema/Controllers/Index");
    }
  },
};
</script>
