<style>
.vue-pdf-embed > div {
  margin-bottom: 8px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}
</style>
<style scoped>
::v-deep .v-data-table tbody tr.v-data-table__selected {
  background: darkgrey !important;
  color: white !important;
}
</style>
<template>
  <div :style="HideEdit">
    <v-card>
      <loadingEdit v-if="showLoading"></loadingEdit>
      <template v-else>
        <v-card-text class="pl-1 pr-1">
          <v-col cols="12" offset="0">
            <v-card>
              <v-app-bar color="primary" dense>
                <v-toolbar-title class="white--text">Ata</v-toolbar-title>
              </v-app-bar>
              <v-card-text class="bordaappbar">
                <v-row dense>
                  <v-col cols="1" offset="0">
                    <v-text-field outlined dense hide-details label="Id" v-model="Model.Id" readonly :style="StyleReadOnly"></v-text-field>
                  </v-col>
                  <v-col cols="6" md="6" lg="6" offset="0">
                    <v-text-field outlined dense hide-details label="Nome" v-model="Model.Nome"></v-text-field>
                  </v-col>
                  <v-col cols="6" md="2" lg="2">
                    <v-text-field outlined dense hide-details label="Número" type="Number" hide-spin-buttons v-model="Model.Numero" :readonly="NumeroReadOnly" :style="StyleNumeroReadOnly"></v-text-field>
                  </v-col>
                  <v-col cols="6" md="3" lg="3">
                    <v-btn-toggle v-model="Model.Status" dense divided mandatory :color="CorStatusAta" style="width: 100%">
                      <v-btn value="A" style="height: 40px; width: 50%">ABERTO</v-btn>
                      <v-btn value="F" style="height: 40px; width: 50%" @click="ShowFechaAta = true">FECHADO</v-btn>
                    </v-btn-toggle>
                    <confirm
                      Msg="Fechar ATA?"
                      MsgText="Deseja realmente fechar a ATA ?"
                      :Show="ShowFechaAta"
                      v-on:confirm="ShowFechaAta = false"
                      v-on:cancel="
                        ShowFechaAta = false;
                        Model.Status = 'A';
                      "
                    ></confirm>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" md="6" lg="6" offset="0">
                    <v-autocomplete return-object :items="Empresas" item-text="Nome" item-value="Id" v-model="Model.Empresa" label="Empresa" dense outlined hide-details :readonly="Model.Id != 0" :style="StyleReadOnly"></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6" lg="6" offset="0">
                    <v-autocomplete return-object :items="AtaTipos" item-text="Nome" item-value="Id" v-model="Model.Tipo" label="Tipo" dense outlined hide-details :readonly="Model.Id != 0" :style="StyleReadOnly"></v-autocomplete>
                  </v-col>
                </v-row>

                <br />

                <span :style="this.AllowAll">
                  <v-row dense>
                    <v-col cols="12" sm="12" md="6" lg="4" :style="Model.AcessoExterno && Model.ChaveAcessoExterno ? StyleCompartilhamentoAtivado : ''">
                      <v-row dense>
                        <v-col cols="9">
                          <v-switch @change="AtivaAcessoExterno" hide-details inset color="success" label="Ativar Link Público" :false-value="false" :true-value="true" v-model="Model.AcessoExterno"></v-switch>
                        </v-col>
                        <v-col v-if="Model.AcessoExterno && Model.ChaveAcessoExterno">
                          <v-btn block @click="CopiaLink('/Ata/Download/'.concat(Model.ChaveAcessoExterno))" color="success" title="Copiar Link Público"><v-icon>mdi-content-copy</v-icon></v-btn>
                        </v-col>
                      </v-row>
                      <v-row dense v-if="Model.AcessoExterno && Model.ChaveAcessoExterno">
                        <v-col>
                          <v-text-field outlined dense hide-details label="Senha de compartilhamento (20 MAX)" style="background-color: white" maxlength="20" :type="MostraSenhaCompartilhamento ? 'text' : 'password'" :append-icon="MostraSenhaCompartilhamento ? 'mdi-eye' : 'mdi-eye-off'" @click:append="MostraSenhaCompartilhamento = !MostraSenhaCompartilhamento" v-model="Model.SenhaAcessoExterno"> </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col>
                      <v-row>
                        <v-col cols="12" sm="12" md="8" lg="6" offset-md="2" offset-lg="0">
                          <v-btn block @click="ShowAta()" color="info">
                            Ver Livro Ata Completo
                            <v-icon class="ml-3">mdi-monitor-eye</v-icon>
                          </v-btn>
                        </v-col>

                        <v-col cols="12" sm="12" md="8" lg="6" offset-md="2" offset-lg="0">
                          <v-btn block @click="JuntarArquivosAta()" color="info">
                            Baixar Livro Ata Completo
                            <v-icon class="ml-3">mdi-file-download</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </span>

                <v-dialog v-model="ShowPDF" width="80%" scrollable>
                  <v-card>
                    <v-card-title class="primary white--text">Visualização de ATA</v-card-title>
                    <v-card-text class="pa-5">
                      <pdf style="display: none" :src="TestePDF" @num-pages="pageCount = $event" />
                      <span v-for="index in pageCount" :key="index">
                        <pdf :src="TestePDF" :page="index" />
                      </span>
                    </v-card-text>
                    <v-card-actions class="pa-5">
                      <v-btn class="ml-auto" @click="ShowPDF = false" outlined color="primary">Fechar</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <br />

                <span v-if="CanEdit">
                  <v-row dense v-if="!Model.TermoDeAbertura.Id">
                    <v-col cols="8" offset="0">
                      <v-text-field outlined dense hide-details label="Descrição do Termo de Abertura" v-model="Model.TermoDeAbertura.Descricao"></v-text-field>
                    </v-col>
                    <v-col>
                      <v-file-input :prepend-icon="null" accept=".pdf" show-size prepend-inner-icon="mdi-file-upload" outlined multiple dense hide-details label="Selecione o Arquivo de Termo de Abertura" v-model="TermoDeAbertura"></v-file-input>
                    </v-col>
                  </v-row>
                  <v-row dense v-else>
                    <template v-if="!Model.TermoDeAbertura.ApagarArquivo">
                      <v-col cols="9" sm="10" md="11" offset="0">
                        <v-text-field outlined dense hide-details label="Termo de Abertura" v-model="Model.TermoDeAbertura.Descricao" readonly></v-text-field>
                      </v-col>
                      <v-col cols="3" sm="2" md="1" style="margin-top: auto; line-height: 40px">
                        <v-icon color="primary" label="Download" @click="ArquivoDownload(Model.TermoDeAbertura)">mdi-file-download</v-icon>
                        <v-icon color="red" aria-label="Apagar" label="apagar" v-if="CanEdit" @click="ArquivoDelete(Model.TermoDeAbertura)">mdi-file-remove</v-icon>
                      </v-col>
                    </template>
                    <template v-else>
                      <v-col cols="9" sm="10" md="11" offset="0">
                        <v-text-field outlined dense hide-details label="Termo de Abertura será APAGADO" v-model="Model.TermoDeAbertura.Descricao" readonly :style="StyleReadOnly"></v-text-field>
                      </v-col>
                      <v-col cols="3" sm="2" md="1" style="margin-top: auto; line-height: 40px">
                        <v-icon color="success" label="Restaurar" @click="ArquivoDelete(Model.TermoDeAbertura)">mdi-file-undo</v-icon>
                      </v-col>
                    </template>
                  </v-row>

                  <v-row dense v-if="!Model.TermoDeFechamento.Id">
                    <v-col cols="8" offset="0">
                      <v-text-field outlined dense hide-details label="Descrição do Termo Fechamento" v-model="Model.TermoDeFechamento.Descricao"></v-text-field>
                    </v-col>
                    <v-col>
                      <v-file-input :prepend-icon="null" accept=".pdf" show-size prepend-inner-icon="mdi-file-upload" outlined multiple dense hide-details label="Selecione o Arquivo de Termo de Fechamento" v-model="TermoDeFechamento"></v-file-input>
                    </v-col>
                  </v-row>
                  <v-row dense v-else>
                    <template v-if="!Model.TermoDeFechamento.ApagarArquivo">
                      <v-col cols="9" sm="10" md="11" offset="0">
                        <v-text-field outlined dense hide-details label="Termo de Fechamento" v-model="Model.TermoDeFechamento.Descricao" readonly></v-text-field>
                      </v-col>
                      <v-col cols="3" sm="2" md="1" style="margin-top: auto; line-height: 40px">
                        <v-icon color="primary" label="Download" @click="ArquivoDownload(Model.TermoDeFechamento)">mdi-file-download</v-icon>
                        <v-icon color="red" aria-label="Apagar" label="apagar" v-if="CanEdit" @click="ArquivoDelete(Model.TermoDeFechamento)">mdi-file-remove</v-icon>
                      </v-col>
                    </template>
                    <template v-else>
                      <v-col cols="9" sm="10" md="11" offset="0">
                        <v-text-field outlined dense hide-details label="Termo de Fechamento será APAGADO" v-model="Model.TermoDeFechamento.Descricao" readonly :style="StyleReadOnly"></v-text-field>
                      </v-col>
                      <v-col cols="3" sm="2" md="1" style="margin-top: auto; line-height: 40px">
                        <v-icon color="success" label="Restaurar" @click="ArquivoDelete(Model.TermoDeFechamento)">mdi-file-undo</v-icon>
                      </v-col>
                    </template>
                  </v-row>
                </span>
                <!--              -->
                <!--  ATAS GERAIS -->
                <!--              -->
                <template v-if="!Dashboard">
                  <v-row dense v-if="CanEdit">
                    <v-col>
                      <v-data-table dense class="elevation-1 bordagrid" item-class="row-pointer" mobile-breakpoint="1" @click:row="RowAtaGeralClick" :sort-by="['DataOperacao']" :sort-desc="[true]" :headers="HeaderAtasGerais" :items="Model.AtasGerais" item-key="Id" single-select hide-default-footer disable-pagination disabled-filtering>
                        <template v-slot:top>
                          <v-toolbar dense text>
                            <v-toolbar-title>Atas Gerais</v-toolbar-title>
                            <v-divider class="mx-1" inset vertical></v-divider>
                          </v-toolbar>
                          <br />
                          <v-row dense class="px-2">
                            <v-col cols="3">
                              <dtPicker label="Data Operação" v-model="NovaAtaGeralDataOperacao"></dtPicker>
                            </v-col>
                            <v-col cols="3">
                              <hrPicker label="Hora Operação" v-model="NovaAtaGeralHoraOperacao"></hrPicker>
                            </v-col>
                          </v-row>
                          <v-row dense class="px-2">
                            <v-col cols="6">
                              <v-text-field outlined dense hide-details label="Descrição" v-model="NovaAtaGeralDescricao"></v-text-field>
                            </v-col>
                            <v-col cols="5">
                              <v-file-input :prepend-icon="null" accept=".pdf" show-size prepend-inner-icon="mdi-file-upload" outlined multiple dense hide-details label="Selecione o arquivo" v-model="NovaAtaGeralArquivo"></v-file-input>
                            </v-col>
                            <v-col>
                              <v-btn block @click="AddAtaGeral()" color="primary"><v-icon>mdi-plus</v-icon></v-btn>
                            </v-col>
                          </v-row>
                          <br />
                          <hr />
                        </template>
                        <template v-slot:item="{ item, expand, isExpanded }">
                          <tr>
                            <td>{{ item.DataOperacao | dateTimeBR }}</td>
                            <td :style="item.ApagarArquivo ? 'text-decoration: line-through; color: red;' : ''">{{ item.Descricao }}</td>
                            <td style="text-wrap: nowrap !important">
                              <v-col cols="1" v-if="!item.ApagarArquivo">
                                <v-icon color="primary" label="Download" @click="ArquivoDownload(item)">mdi-file-download</v-icon>
                                <v-icon color="red" aria-label="Apagar" label="apagar" v-if="CanEdit" @click="ArquivoDelete(item)">mdi-file-remove</v-icon>
                              </v-col>
                              <v-col v-else>
                                <v-icon color="success" label="Restaurar" @click="ArquivoDelete(item)">mdi-file-undo</v-icon>
                              </v-col>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </template>
                <!--                   -->
                <!--  ATAS NOMINATIVAS -->
                <!--                   -->
                <template v-else>
                  <v-row :style="AllowAll">
                    <v-col>
                      <v-data-table class="elevation-1 bordagrid" item-class="row-pointer" mobile-breakpoint="1" @click:row="RowAtaNominativaClick" :headers="HeaderAtasNominativas" :items="Model.AtasNominativas" item-key="CPFCNPJ" hide-default-footer disable-pagination disabled-filtering single-select>
                        <template v-slot:top>
                          <v-toolbar dense text>
                            <v-toolbar-title>Acionistas</v-toolbar-title>
                            <!-- <v-divider class="mx-1" inset vertical></v-divider> -->
                            <v-spacer></v-spacer>
                            <v-text-field outlined dense hide-details label="Nome" v-model="AtaNominativaNome" v-if="CanEdit"></v-text-field>
                            <v-text-field outlined dense hide-details label="CPFCNPJ" v-model="AtaNominativaCPFCNPJ" v-if="CanEdit"></v-text-field>
                            <v-btn @click="AddAtaNominativa()" color="primary" v-if="CanEdit"><v-icon>mdi-plus</v-icon></v-btn>
                          </v-toolbar>
                          <hr />
                        </template>

                        <template v-slot:[`item.Action`]="{ item }" v-if="CanEdit">
                          <v-icon @click.stop="EditAtaNominativa(item)" color="teal">mdi-pencil</v-icon>
                          <v-icon @click="DelAtaNominativa(item)" color="error">mdi-trash-can</v-icon>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                  <v-row v-if="this.AtaNominativaSelecionada && this.MostrarRegistros">
                    <v-col>
                      <v-data-table class="elevation-1 bordagrid" item-class="row-pointer" mobile-breakpoint="1" @click:row="RowRegistroClick" :headers="HeaderRegistro" :items="AtaNominativaSelecionada.Registros" :sort-by="['Indice']" :sort-desc="[false]" item-key="Indice" single-select hide-default-footer disable-pagination disabled-filtering>
                        <template v-slot:top>
                          <v-toolbar dense text>
                            <v-toolbar-title>Ações - {{ AbstractNome }}</v-toolbar-title>
                            <v-divider class="mx-1" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-btn @click="AddRegistro()" color="primary" v-if="CanEdit"><v-icon>mdi-plus</v-icon></v-btn>
                          </v-toolbar>
                          <hr />
                        </template>

                        <template v-slot:[`item.DataOperacao`]="{ item }">
                          <span class="text-no-wrap">
                            {{ item.DataOperacao | dateBR }}
                          </span>
                        </template>

                        <template v-slot:[`item.QuantidadeAcoesReferenteOperacao`]="{ item }">
                          {{ item.QuantidadeAcoesReferenteOperacao | numeralFormat }}
                        </template>

                        <template v-slot:[`item.NaturezaOperacao`]="{ item }">
                          {{ GetNaturezaOperacao(item.NaturezaOperacao) }}
                        </template>

                        <template v-slot:[`item.Especie`]="{ item }">
                          {{ item.Especie == 1 ? "PN" : item.Especie == 2 ? "ON" : "" }}
                        </template>

                        <template v-slot:[`item.ValorNominal`]="{ item }">
                          {{ item.ValorNominal | currencyBR }}
                        </template>

                        <template v-slot:[`item.ValorIntegralizado`]="{ item }">
                          {{ item.ValorIntegralizado | currencyBR }}
                        </template>

                        <template v-slot:[`item.ValorAIntegralizar`]="{ item }">
                          {{ item.ValorAIntegralizar | currencyBR }}
                        </template>

                        <template v-slot:[`item.PrazoParaIntegralizacao`]="{ item }">
                          <span class="text-no-wrap">
                            {{ item.PrazoParaIntegralizacao | dateBR }}
                          </span>
                        </template>

                        <template v-slot:[`item.Indice`]="{ item }">
                          {{ item.Indice }}
                        </template>

                        <template v-slot:[`item.Action`]="{ item }" v-if="CanEdit">
                          <v-icon @click.stop="DelRegistro(item)" color="error">mdi-trash-can</v-icon>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </template>
              </v-card-text>
            </v-card>
          </v-col>
          <v-row dense :style="this.AllowAll">
            <v-col cols="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
              <v-row>
                <v-col>
                  <v-btn block @click="ShowConfirmaExcluir = true" color="error" v-if="Model.Id != 0 && CanDelete">Excluir Livro Ata</v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-col>
                  <v-btn block @click="Index()" color="warning">Cancelar</v-btn>
                </v-col>
                <v-col>
                  <v-btn block @click="ShowConfirmaSalvar = true" color="primary" v-if="CanEdit">Salvar</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </template>
    </v-card>

    <v-dialog v-model="DialogEditAtaGeral" width="800">
      <v-card>
        <v-app-bar color="primary" dense>
          <v-toolbar-title class="white--text">Edição de Ata Geral</v-toolbar-title>
        </v-app-bar>
        <v-card-text>
          <v-container class="pt-10" v-if="AtaGeralSelecionadaEdit">
            <template>
              <v-row dense>
                <v-col cols="12" moffset="0">
                  <v-file-input :prepend-icon="null" accept=".pdf" show-size prepend-inner-icon="mdi-file-upload" outlined dense hide-details label="Arquivo" v-model="AtaGeralSelecionadaEdit.Arquivo"></v-file-input>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" offset="0">
                  <dtPicker label="Data Operação" v-model="AtaGeralSelecionadaEdit.DataOperacao"></dtPicker>
                </v-col>
              </v-row>

              <v-card-actions class="pt-10">
                <v-spacer></v-spacer>
                <v-btn x-large color="warning" @click="DialogEditAtaGeral = false">Cancelar</v-btn>
                <v-btn x-large color="primary" @click="RowAtaGeralSave()">Salvar</v-btn>
              </v-card-actions>
            </template>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="DialogEditAtaNominativa" width="800">
      <v-card>
        <v-app-bar color="primary" dense>
          <v-toolbar-title class="white--text">Edição de Acionista</v-toolbar-title>
        </v-app-bar>
        <v-card-text>
          <v-container class="pt-10" v-if="AtaNominativaSelecionadaEdit">
            <template>
              <v-row dense>
                <v-col cols="12" moffset="0">
                  <v-text-field outlined dense hide-details label="Nome" v-model="AtaNominativaSelecionadaEdit.Nome"></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" moffset="0">
                  <v-text-field outlined dense hide-details label="CPF/CNPJ" v-model="AtaNominativaSelecionadaEdit.CPFCNPJ"></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" moffset="0">
                  <v-text-field outlined dense hide-details label="Endereço" v-model="AtaNominativaSelecionadaEdit.Endereco"></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" moffset="0">
                  <v-text-field outlined dense hide-details label="Cidade/UF/CEP" v-model="AtaNominativaSelecionadaEdit.CidadeUfCep"></v-text-field>
                </v-col>
              </v-row>
              <v-card-actions class="pt-10">
                <v-spacer></v-spacer>
                <v-btn x-large color="warning" @click="DialogEditAtaNominativa = false">Cancelar</v-btn>
                <v-btn x-large color="primary" @click="RowAtaNominativaSave()">Salvar</v-btn>
              </v-card-actions>
            </template>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="DialogEditRegistro" width="1000">
      <v-card>
        <v-app-bar color="primary" dense>
          <v-toolbar-title v-if="NovoRegistro" class="white--text">Novo Registro</v-toolbar-title>
          <v-toolbar-title v-else class="white--text">Edição de Registro</v-toolbar-title>
        </v-app-bar>
        <v-card-text>
          <v-container class="pt-10" v-if="RegistroSelecionadoEdit">
            <template>
              <v-row dense>
                <v-col cols="6" md="3" lg="3">
                  <dtPicker label="Data Operação" v-model="RegistroSelecionadoEdit.DataOperacao"></dtPicker>
                </v-col>
                <v-col cols="6" md="3" lg="3">
                  <v-autocomplete :items="NaturezasOperacao" item-text="Nome" item-value="Id" v-model="RegistroSelecionadoEdit.NaturezaOperacao" label="Natureza da Operação" dense outlined hide-details></v-autocomplete>
                </v-col>
                <v-col cols="6" md="3" lg="3">
                  <v-text-field outlined dense hide-details label="Folha" v-model="RegistroSelecionadoEdit.Folha"></v-text-field>
                </v-col>
                <v-col cols="6" md="3" lg="3">
                  <v-text-field outlined dense hide-details label="Termo" v-model="RegistroSelecionadoEdit.Termo"></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" md="4" lg="4">
                  <v-text-field outlined dense hide-details label="Qtd. de Ações Referente à Operação" v-model="RegistroSelecionadoEdit.QuantidadeAcoesReferenteOperacao"></v-text-field>
                </v-col>
                <v-col cols="12" md="4" lg="4">
                  <v-autocomplete :items="Especies" item-text="Nome" item-value="Id" v-model="RegistroSelecionadoEdit.Especie" label="Espécie" dense outlined hide-details></v-autocomplete>
                </v-col>
                <v-col cols="12" md="4" lg="4">
                  <v-text-field outlined dense hide-details label="Classe" v-model="RegistroSelecionadoEdit.Classe"></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" md="4" lg="4">
                  <v-currency-field prefix="R$" outlined dense hide-details label="Valor Nominal" v-model="RegistroSelecionadoEdit.ValorNominal"></v-currency-field>
                </v-col>
                <v-col cols="12" md="4" lg="4">
                  <v-currency-field prefix="R$" outlined dense hide-details label="Valor Integralizado" v-model="RegistroSelecionadoEdit.ValorIntegralizado"></v-currency-field>
                </v-col>
                <v-col cols="12" md="4" lg="4">
                  <v-currency-field prefix="R$" outlined dense hide-details label="Valor a Integralizar" v-model="RegistroSelecionadoEdit.ValorAIntegralizar"></v-currency-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" md="4" lg="4">
                  <dtPicker label="Prazo para Integralização" v-model="RegistroSelecionadoEdit.PrazoParaIntegralizacao"></dtPicker>
                </v-col>
                <v-col cols="12" md="4" lg="4">
                  <v-text-field outlined dense hide-details label="Ações Orinárias Após Operação" type="Number" hide-spin-buttons v-model="RegistroSelecionadoEdit.TotalAcoesOrdinariasAposOperacao"></v-text-field>
                </v-col>
                <v-col cols="12" md="4" lg="4">
                  <v-text-field outlined dense hide-details label="Ações Prefereiciais Após Operação" type="Number" hide-spin-buttons v-model="RegistroSelecionadoEdit.TotalAcoesPreferenciaisAposOperacao"></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" md="4" lg="4">
                  <v-text-field outlined dense hide-details label="Índice de Averbações ou Ônus" type="Number" hide-spin-buttons v-model="RegistroSelecionadoEdit.Indice"></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <v-textarea outlined dense hide-details rows="3" label="Índice de Averbações e Ônus" v-model="RegistroSelecionadoEdit.AverbacaoOnus"></v-textarea>
                </v-col>
              </v-row>

              <v-card-actions class="pt-10">
                <v-spacer></v-spacer>
                <v-btn x-large color="warning" @click="DialogEditRegistro = false">Cancelar</v-btn>
                <v-btn x-large color="primary" @click="RowRegistroSave()">Salvar</v-btn>
              </v-card-actions>
            </template>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <confirm Msg="Excluir?" :Show="ShowConfirmaExcluir" v-on:confirm="Delete(), (ShowConfirmaExcluir = false)" v-on:cancel="ShowConfirmaExcluir = false"></confirm>
    <confirm Msg="Salvar?" :Show="ShowConfirmaSalvar" v-on:confirm="Save(), (ShowConfirmaSalvar = false)" v-on:cancel="ShowConfirmaSalvar = false"></confirm>
  </div>
</template>

<script>
import axios from "axios";
// import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import pdf from "vue-pdf";

export default {
  components: {
    // VuePdfEmbed,
    pdf,
  },
  name: "AtaEdit",
  data() {
    return {
      ShowDialog: true,
      Model: {
        Tipo: {},
        TermoDeAbertura: {
          Descricao: "",
        },
        TermoDeFechamento: {
          Descricao: "",
        },
        ChaveAcessoExterno: null,
        SenhaAcessoExterno: "",
      },
      AtaTipos: [],
      Empresas: [],
      Especies: [],
      NaturezasOperacao: [],
      NivelAcesso: [],
      ShowConfirmaExcluir: false,
      ShowConfirmaSalvar: false,
      TermoDeAbertura: [],
      TermoDeFechamento: [],
      DialogEditRegistro: false,
      DialogEditAtaNominativa: false,
      DialogEditAtaGeral: false,
      RegistroSelecionado: null,
      RegistroSelecionadoEdit: null,
      AtaNominativaSelecionada: {
        Registros: [],
      },
      AtaNominativaSelecionadaEdit: {
        Registros: [],
      },
      MostraSenhaCompartilhamento: false,
      StyleCompartilhamentoAtivado: "border-radius: 4px; background-color:rgba(76, 175, 80,0.18);",
      AtaGeralSelecionada: null,
      AtaGeralSelecionadaEdit: null,
      AtaNominativaNome: "",
      AtaNominativaCPFCNPJ: "",
      NovaAtaGeralDataOperacao: "",
      NovaAtaGeralHoraOperacao: "",
      NovaAtaGeralDescricao: "",
      NovaAtaGeralArquivo: [],
      NovaAtaNominativa: false,
      NovoRegistro: false,
      MostrarRegistros: false,
      IsPermitted: 0,
      CanEdit: false,
      CanDelete: false,
      AllowAll: false,
      HideEdit: false,
      HideDelete: false,
      AbstractNome: "",
      TestePDF: null,
      ShowPDF: false,
      pageCount: 0,
      currentPage: 0,
      ShowFechaAta: false,
      HeaderRegistro: [
        { text: "Data Operacão", value: "DataOperacao", sortable: false, align: "center", divider: true, width: "20" },
        { text: "Natureza da Operacao", value: "NaturezaOperacao", sortable: false, align: "center", divider: true },
        { text: "Qtd. de Açôes Referente à Operacao", value: "QuantidadeAcoesReferenteOperacao", sortable: false, align: "center", divider: true },
        { text: "Espécie", value: "Especie", sortable: false, align: "center", divider: true },
        { text: "Classe", value: "Classe", sortable: false, align: "center", divider: true },
        { text: "Valor Nominal", value: "ValorNominal", sortable: false, align: "right", divider: true },
        { text: "Valor Integralizado", value: "ValorIntegralizado", sortable: false, align: "right", divider: true },
        { text: "Valor à Integralizar", value: "ValorAIntegralizar", sortable: false, align: "right", divider: true },
        { text: "Prazo Para Integralização", value: "PrazoParaIntegralizacao", sortable: false, align: "center", divider: true },
        { text: "Total Ações Ordinárias Após Operação", value: "TotalAcoesOrdinariasAposOperacao", sortable: false, align: "center", divider: true },
        { text: "Total Ações Preferenciais Após Operação", value: "TotalAcoesPreferenciaisAposOperacao", sortable: false, align: "center", divider: true },
        { text: "Folha", value: "Folha", sortable: false, align: "center", divider: true },
        { text: "Termo", value: "Termo", sortable: false, align: "center", divider: true },
        { text: "Índice de Averbações e Ônus", value: "Indice", sortable: false, align: "center", divider: true },
        { text: "", value: "Action", sortable: false, width: "60px", align: "center" },
      ],
      HeaderAtasNominativas: [
        { text: "Nome", value: "Nome", sortable: false },
        { text: "CPF/CNPJ", value: "CPFCNPJ", sortable: false },
        { text: "", value: "Action", sortable: false, width: "90px", align: "center" },
      ],
      HeaderAtasGerais: [
        { text: "Data/Hora Operacão", value: "DataOperacao", sortable: false, width: "250px" },
        { text: "Arquivo", value: "Descricao", sortable: false },
        { text: "", value: "Action", sortable: false, width: "100px", align: "center" },
      ],
    };
  },
  errorCaptured() {
    return false;
  },
  methods: {
    Edit(Id) {
      axios.get("/api/Ata/Edit/".concat(Id)).then((response) => {
        let data = response.data.Data;
        if (data != null) {
          if (!data.TermoDeAbertura) data.TermoDeAbertura = { Descricao: "" };

          if (!data.TermoDeFechamento) data.TermoDeFechamento = { Descricao: "" };

          this.Model = data;
          this.LoadPermission();
        }
      });
    },
    async Save() {
      if (this.Model.AcessoExterno) {
        if (this.Model.SenhaAcessoExterno === null) {
          this.$toast.error("Senha obrigatória para compartilhamento externo.");
          return;
        }

        if (typeof this.Model.SenhaAcessoExterno === "string" && this.Model.SenhaAcessoExterno.length === 0) {
          this.$toast.error("Senha obrigatória para compartilhamento externo.");
          return;
        }
      }

      if (this.TermoDeAbertura.length > 0) {
        if (!this.Model.TermoDeAbertura.Descricao) {
          this.$toast.success("Descrição do Termo de Abertura não pode estar em branco");
          return;
        }

        let DescricaoTermoAbertura = this.Model.TermoDeAbertura.Descricao;
        this.Model.TermoDeAbertura = await this.ArquivoUpload(this.TermoDeAbertura);
        this.Model.TermoDeAbertura.Descricao = DescricaoTermoAbertura;
      } else if (Object.hasOwn(this.Model.TermoDeAbertura, "Id")) {
        if (!this.Model.TermoDeAbertura.Id) this.Model.TermoDeAbertura = null;
      } else {
        this.Model.TermoDeAbertura = null;
      }

      if (this.TermoDeFechamento.length > 0) {
        if (!this.Model.TermoDeFechamento.Descricao) {
          this.$toast.success("Descrição do Termo de Abertura não pode estar em branco");
          return;
        }

        let DescricaoTermoFechamento = this.Model.TermoDeFechamento.Descricao;
        this.Model.TermoDeFechamento = await this.ArquivoUpload(this.TermoDeFechamento);
        this.Model.TermoDeFechamento.Descricao = DescricaoTermoFechamento;
      } else if (Object.hasOwn(this.Model.TermoDeFechamento, "Id")) {
        if (!this.Model.TermoDeFechamento.Id) this.Model.TermoDeFechamento = null;
      } else {
        this.Model.TermoDeFechamento = null;
      }

      await axios({ method: "post", data: this.Model, url: "/api/Ata/Save" })
        .then((response) => {
          this.Index();
        })
        .catch((error) => {
          //
        });
    },
    Delete() {
      axios({ method: "delete", data: this.Model, url: "/api/Ata/Delete" })
        .then((response) => {
          this.Index();
          this.$toast.success("Dados deletados");
        })
        .catch((error) => {
          //
        });
    },
    Index() {
      this.$router.push("/Ata/Index");
    },
    async ArquivoUpload(arquivo) {
      let Arquivo;
      var up_axios = axios.create();
      await up_axios({ method: "post", data: arquivo, url: "/api/Arquivos/Upload", headers: { "Content-type": "multipart/form-data" } })
        .then((response) => {
          Arquivo = response.data.Data[0];
        })
        .catch((error) => {
          return null;
        });
      return Arquivo;
    },
    ArquivoDownload(caminho) {
      let Arquivo = JSON.parse(JSON.stringify(caminho));

      axios({ method: "post", responseType: "blob", data: Arquivo, url: "/api/Arquivos/Download" }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", Arquivo.Nome);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    CopiaLink(link) {
      var full = window.location.origin.concat(link);
      navigator.clipboard.writeText(full);
      this.$toast.success("Link copiado em memória");
    },
    ArquivoDelete(caminho) {
      this.$set(caminho, "ApagarArquivo", !caminho.ApagarArquivo);
      this.$toast.success("Arquivo deletado");
    },
    GetListas() {
      const instance = axios.create();

      const GetAtaTipos = instance.get("/api/Lista/AtaTipo");
      const GetEmpresas = instance.get("/api/Lista/Empresa");
      const GetEspecies = instance.get("/api/Lista/Especies");
      const GetNaturezasOperacao = instance.get("/api/Lista/NaturezasOperacao");

      axios
        .all([GetAtaTipos, GetEmpresas, GetEspecies, GetNaturezasOperacao])
        .then(
          axios.spread((...responses) => {
            responses[0].data.forEach((item) => {
              try {
                this.AtaTipos.push(item);
              } catch {}
            });

            responses[1].data.forEach((item) => {
              try {
                this.Empresas.push(item);
              } catch {}
            });

            responses[2].data.forEach((item) => {
              try {
                this.Especies.push(item);
              } catch {}
            });

            responses[3].data.forEach((item) => {
              try {
                this.NaturezasOperacao.push(item);
              } catch {}
            });
          })
        )
        .catch((errors) => {});
    },
    RowRegistroClick(item) {
      this.RegistroSelecionadoEdit = JSON.parse(JSON.stringify(item));
      this.RegistroSelecionado = item;
      this.NovoRegistro = false;
      this.DialogEditRegistro = true;
    },
    RowRegistroSave() {
      let membro = this.AtaNominativaSelecionada.Registros.indexOf(this.RegistroSelecionado);

      if (membro >= 0) this.AtaNominativaSelecionada.Registros.splice(membro, 1, this.RegistroSelecionadoEdit);
      else {
        this.RegistroSelecionadoEdit.Indice = this.RegistroSelecionadoEdit.Indice ?? this.AtaNominativaSelecionada.Registros.length + 1;
        this.AtaNominativaSelecionada.Registros.push(this.RegistroSelecionadoEdit);
      }

      this.RegistroSelecionadoEdit = null;

      this.DialogEditRegistro = false;
      this.$toast.success("Registro salvo");
    },
    AddRegistro() {
      this.RegistroSelecionadoEdit = {};
      this.RegistroSelecionadoEdit.IdAtaNominativa = this.AtaNominativaSelecionada.Id || 0;
      this.RegistroSelecionadoEdit.ValorNominal = 0;
      this.RegistroSelecionadoEdit.ValorIntegralizado = 0;
      this.RegistroSelecionadoEdit.ValorAIntegralizar = 0;
      this.RegistroSelecionadoEdit.TotalAcoesOrdinariasAposOperacao = 0;
      this.RegistroSelecionadoEdit.TotalAcoesPreferenciaisAposOperacao = 0;
      this.RegistroSelecionadoEdit.QuantidadeAcoesReferenteOperacao = 0;
      this.NovoRegistro = true;
      this.DialogEditRegistro = true;
    },
    DelRegistro(item) {
      this.AtaNominativaSelecionada.Registros.splice(this.AtaNominativaSelecionada.Registros.indexOf(item), 1);
      this.$toast.success("Registro excluído");
    },
    RowAtaNominativaClick(item, row) {
      row.select(true);
      this.AtaNominativaSelecionadaEdit = JSON.parse(JSON.stringify(item));
      this.AtaNominativaSelecionada = item;
      this.MostrarRegistros = true;
      this.AbstractNome = this.AtaNominativaSelecionada.Nome;
    },
    RowAtaNominativaSave() {
      let membro = this.Model.AtasNominativas.find((x) => x == this.AtaNominativaSelecionada);

      let CPFCNPJ = this.Model.AtasNominativas.find((x) => x.CPFCNPJ == this.AtaNominativaSelecionadaEdit.CPFCNPJ);
      if (CPFCNPJ && CPFCNPJ != membro) {
        this.$toast.error("Já existe CPF/CNPJ Cadastro");
        return false;
      }

      if (this.AtaNominativaSelecionadaEdit.Registros == null) this.AtaNominativaSelecionadaEdit.Registros = [];

      if (membro) this.Model.AtasNominativas.splice(this.Model.AtasNominativas.indexOf(this.AtaNominativaSelecionada), 1, this.AtaNominativaSelecionadaEdit);
      else
        this.Model.AtasNominativas.push({
          IdAta: this.Model.Id,
          Nome: this.AtaNominativaNome,
          CPFCNPJ: this.AtaNominativaCPFCNPJ,
          Registros: [],
        });

      this.AtaNominativaSelecionada = null;
      this.DialogEditAtaNominativa = false;
      this.$toast.success("Registro salvo");

      return true;
    },
    AddAtaNominativa() {
      if (this.AtaNominativaNome.length == 0 || this.AtaNominativaCPFCNPJ.length == 0) {
        this.$toast.error("Nome e CNPJ precisam ser preenchidos");
        return false;
      }

      this.NovaAtaNominativa = true;

      if (this.RowAtaNominativaSave()) {
        var item = this.Model.AtasNominativas.find((x) => x.CPFCNPJ == this.AtaNominativaCPFCNPJ);
        this.RowAtaNominativaClick(item);

        this.AtaNominativaNome = "";
        this.AtaNominativaCPFCNPJ = "";
      }
      this.$toast.success("Registro adicionado");
    },
    EditAtaNominativa(item) {
      this.AtaNominativaSelecionadaEdit = JSON.parse(JSON.stringify(item));
      this.AtaNominativaSelecionada = item;
      this.DialogEditAtaNominativa = true;
      this.MostrarRegistros = false;
      this.NovaAtaNominativa = false;
    },
    DelAtaNominativa(item) {
      this.Model.AtasNominativas.splice(this.Model.AtasNominativas.indexOf(item), 1);
      this.$toast.success("Registro deletado");
    },

    RowAtaGeralClick(item) {
      this.AtaGeralSelecionadaEdit = JSON.parse(JSON.stringify(item));
      this.AtaGeralSelecionada = item;
    },
    RowAtaGeralSave() {
      let membro = this.Model.AtasGerais.indexOf(this.AtaGeralSelecionada);

      if (membro >= 0) this.Model.AtasGerais.splice(membro, 1, this.AtaGeralSelecionadaEdit);
      else this.Model.AtasGerais.push(this.AtaGeralSelecionadaEdit);

      this.AtaGeralSelecionada = null;
      this.DialogEditAtaGeral = false;
      this.$toast.success("Registro salvo");
    },
    async AddAtaGeral() {
      if (!this.NovaAtaGeralDataOperacao || !this.NovaAtaGeralHoraOperacao || !this.NovaAtaGeralDescricao) {
        this.$toast.error("Data/Hora de Operação e Descrição não podem estar em branco");
        return;
      }

      if (this.NovaAtaGeralArquivo.length == 0) {
        this.$toast.error("Escolha um arquivo para upload");
        return;
      }

      let arquivo = await this.ArquivoUpload(this.NovaAtaGeralArquivo);

      if (arquivo) {
        this.Model.AtasGerais.push({
          IdAta: this.Model.Id,
          DataOperacao: this.NovaAtaGeralDataOperacao.substring(0, 10) + "T" + this.NovaAtaGeralHoraOperacao,
          Descricao: this.NovaAtaGeralDescricao,
          ArquivoTemporario: true,
          Nome: arquivo.Nome,
          Caminho: arquivo.Caminho,
        });
      }

      this.NovaAtaGeralDataOperacao = null;
      this.NovaAtaGeralHoraOperacao = null;
      this.NovaAtaGeralDescricao = null;
      this.NovaAtaGeralArquivo = [];
      this.$toast.success("Registro adicionado");
    },
    GetNaturezaOperacao(item) {
      let Natureza = this.NaturezasOperacao.find((x) => x.Id === item);

      if (Natureza) return Natureza.Nome;

      return " - ";
    },
    EditAtaGeral(item) {
      this.AtaGeralSelecionadaEdit = JSON.parse(JSON.stringify(item));
      this.AtaGeralSelecionada = item;
      this.DialogEditAtaGeral = true;
      this.NovaAtaGeral = false;
    },
    DelAtaGeral(item) {
      this.Model.AtasGerais.splice(this.Model.AtasGerais.indexOf(item), 1);
      this.$toast.success("Registro deletado");
    },
    MoveRowDown(item) {
      if (item.Indice < this.AtaNominativaSelecionada.Registros.length) {
        var IndiceAtual = item.Indice;
        var IndiceProximo = item.Indice + 1;
        let atual = this.AtaNominativaSelecionada.Registros.find((x) => x.Indice == item.Indice);
        let proximo = this.AtaNominativaSelecionada.Registros.find((x) => x.Indice == item.Indice + 1);

        if (atual && proximo) {
          proximo.Indice = IndiceAtual;
          atual.Indice = IndiceProximo;
          //proximo = item.Indice - 1;
        }
      }
    },
    MoveRowUp(item) {
      if (item.Indice > 1) {
        var IndiceAtual = item.Indice;
        var IndiceAnterior = item.Indice - 1;
        let atual = this.AtaNominativaSelecionada.Registros.find((x) => x.Indice == item.Indice);
        let anterior = this.AtaNominativaSelecionada.Registros.find((x) => x.Indice == item.Indice - 1);

        if (atual && anterior) {
          anterior.Indice = IndiceAtual;
          atual.Indice = IndiceAnterior;
        }
      }
    },
    JuntarArquivosAta() {
      axios({ method: "post", responseType: "blob", data: this.Model, url: "/api/Arquivos/JuntarArquivosAta" }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "AtaFechada.pdf");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    ClickAta() {
      this.ShowPDF = true;
    },
    ShowAta() {
      this.ShowPDF = true;
      let tempX = null;
      axios({ method: "post", responseType: "blob", data: this.Model, url: "/api/Arquivos/JuntarArquivosAta" }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        this.TestePDF = url;
      });
    },
    AtivaAcessoExterno() {
      if (!this.Model.ChaveAcessoExterno) {
        axios({ method: "get", url: "/api/Arquivos/GerarChaveAcessoExterno" })
          .then((response) => {
            this.$set(this.Model, "ChaveAcessoExterno", response.data.Data.Caminho);
          })
          .catch((error) => {
            //
          });
      }
    },
    LoadPermission() {
      axios({ method: "get", data: null, url: "/api/Lista/GetAcesso" })
        .then((response) => {
          this.IsPermitted = response.data;
          this.HideEdit = this.$functions.EditPermitted(this.IsPermitted);
          this.CanEdit = Boolean(this.$functions.EditPermitted(this.IsPermitted, 1));
          this.AllowAll = this.$functions.AllowPermitted();
          this.HideDelete = this.$functions.DeletePermitted(this.IsPermitted);
          this.CanDelete = Boolean(this.$functions.DeletePermitted(this.IsPermitted, 1));
        })
        .catch((error) => {
          //
        });
    },
    // StatusAta() {
    //   if (this.Model.Status == "F") {
    //     this.CanEdit = false;
    //     this.HideEdit = this.$functions.EditPermitted(false);
    //   } else {
    //     LoadPermission();
    //   }
    // },
  },
  computed: {
    CorStatusAta() {
      return this.Model.Status === "A" ? "success" : "error";
    },
    Dashboard() {
      if (this.Model.Tipo) return this.Model.Tipo.FlagNormativa == true;
      else return false;

      // if (this.Model.Tipo) return this.Model.Tipo.FlagNormativa == true && this.Model.Status == "A";
      // else return false;
    },
    StyleReadOnly() {
      return this.Model.Id != 0 ? "background-color: #d1d1d1" : "";
    },
    StyleNumeroReadOnly() {
      return this.NumeroReadOnly ? "background-color: #d1d1d1" : "";
    },
    NumeroReadOnly() {
      if (this.Model.Empresa) {
        if (this.Model.Empresa.NumeroAtas != 0 && this.Model.Id == 0) {
          this.Model.Numero = 0;
          return true;
        } else if (this.Model.Empresa.NumeroAtas == 0 && this.Model.Id != 0) {
          return true;
        } else {
          return false;
        }
      }

      return true;
    },
    showLoading() {
      return this.$store.getters.showLoading;
    },
  },
  mounted: function () {
    let id = this.$route.params.id;
    if (id != null) {
      this.GetListas();
      this.Edit(id);
    } else {
      this.$router.push("/Ata/Index");
    }
  },
};
</script>
