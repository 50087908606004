<template>
  <section>
    <v-btn fab small ligth color="secondary" @click="Show = true">
      <v-icon> mdi-key-outline </v-icon>
    </v-btn>

    <v-flex xs12 sm12 md12>
      <v-dialog persistent v-model="showDialog" width="360px">
        <v-card>
          <v-app-bar color="primary" dense>
            <v-toolbar-title class="white--text"> ALTERAÇÃO DE SENHA </v-toolbar-title>
          </v-app-bar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field outlined dense hide-details label="Senha Atual" prepend-inner-icon="mdi-key" color="primary" :type="'password'" v-model="usrNovaSenha.Senha" autocomplete="off"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined dense hide-details label="Nova Senha" prepend-inner-icon="mdi-key" color="warning"  :type="'password'" v-model="usrNovaSenha.NovaSenha" autocomplete="off"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined dense hide-details label="Confirmar Nova Senha" prepend-inner-icon="mdi-key" color="success"  :type="'password'" v-model="usrNovaSenha.ConfirmaSenha" autocomplete="off"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn  color="error" id="btnConfirmNo" @click="Show = false">Cancelar</v-btn>
            <v-spacer></v-spacer>
            <v-btn  color="success" id="btnConfirmYes" @click="confirmPasswdSave = true">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-flex>

    <confirm Msg="Salvar Senha?" :Show="confirmPasswdSave" @confirm="postPasswdSave(), (confirmPasswdSave = false)" @cancel="confirmPasswdSave = false"></confirm>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "trocaSenha",
  data() {
    return {
      Show: false,
      confirmPasswdSave: false,
      usrNovaSenha: {
        Senha: "",
        NovaSenha: "",
        ConfirmaSenha: "",
      },
    };
  },
  methods: {
    postPasswdSave() {
      axios({
        method: "post",
        data: this.usrNovaSenha,
        url: "/api/Sistema/Usuarios/TrocarSenha",
      }).then((response) => {
        this.Show = false;
        this.usrNovaSenha.Senha = "";
        this.usrNovaSenha.NovaSenha = "";
        this.usrNovaSenha.ConfirmaSenha = "";
      });
    },
  },
  computed: {
    showDialog() {
      return this.Show;
    },
    usrOpe() {
      return this.$store.getters.usrOpe;
    },
  },
};
</script>
