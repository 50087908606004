<template>
  <v-container fill-height fluid>
    <v-row class="grow" align="center" justify="center">
      <v-col lg="6" md="8" sm="10" xs="12">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title> Sistema ATA - Download de Livro Ata</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-form>
              <template v-if="LinkValido">
                <v-row>
                  <v-col cols="12">
                    <v-text-field outlined dense hide-details label="Senha Para Download" prepend-inner-icon="mdi-key" color="primary" v-model="Model.SenhaAcessoExterno" @keydown.enter.prevent="Download()" autocomplete="off"></v-text-field>
                  </v-col>
                </v-row>
              </template>
              <template v-else>
                <v-row>
                  <v-col>
                    <br />
                    <v-alert border="right" colored-border type="info" elevation="2"> Link de Download Expirado ou Inválido</v-alert>
                  </v-col>
                </v-row>
              </template>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn v-if="LinkValido" block @click="Download()" color="primary">Baixar Livro</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "UploadS3",
  data() {
    return {
      Model: {
        ChaveAcessoExterno: "",
        SenhaAcessoExterno: "",
      },
      LinkValido: false,
    };
  },
  methods: {
    Download() {
      axios({ method: "post", responseType: "blob", data: this.Model, url: "/api/Arquivos/DownloadAtaCompartilhada" }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Ata.pdf");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch((error) => {
        //
        });
    },
    VerificaLink() {
      axios({ method: "post", data: this.Model, url: "/api/Ata/VerificaLinkAta" })
        .then((response) => {
          const data = response.data;
          this.LinkValido = true;
        })
        .catch((error) => {
          this.LinValido = false;
        });
    },
  },
  mounted: function () {
    let id = this.$route.params.id;
    if (id != null) {
      this.Model.ChaveAcessoExterno = id;
      this.VerificaLink();
    } else {
      this.$router.push("/Logout");
    }
  },
};
</script>
