<template>
  <div :style="HideEdit">
    <v-card>
      <loadingEdit v-if="this.$store.getters.showLoading"></loadingEdit>
      <template v-else>
        <v-card-text class="pl-1 pr-1">
          <v-row dense>
            <v-col col="4" md="2" lg="2" offset="0" offset-md="2" offset-lg="2">
              <v-text-field outlined dense hide-details label="Id" v-model="Model.Id" readonly></v-text-field>
            </v-col>
          </v-row>
          <v-row dense style="height: 48px !important">
            <v-col col="6" md="4" lg="4" offset="0" offset-md="2" offset-lg="2">
              <v-switch hide-details inset label="Permissão de Grupo" color="primary" v-model="Model.FlagPermissaoDeGrupo" :false-value="false" :true-value="true" @change="AlteraPermissaoGrupo()"></v-switch>
            </v-col>
            <v-col col="6" md="4" lg="4">
              <v-switch hide-details inset label="Grupo de Controler" color="primary" v-model="Model.FlagGrupoDeController" :false-value="false" :true-value="true" @change="AlteraPermissaoConroller()"></v-switch>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
              <v-autocomplete v-if="Model.FlagPermissaoDeGrupo == true" return-object :items="GrupoUsers" item-text="Nome" item-value="Id" v-model="Model.GrupoUsuario" label="Grupo de Usuário" dense outlined hide-details></v-autocomplete>
              <v-autocomplete v-else return-object :items="Usuarios" item-text="Nome" item-value="Id" v-model="Model.Usuario" label="Usuário" dense outlined hide-details></v-autocomplete>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
              <v-autocomplete v-if="Model.FlagGrupoDeController == true" return-object :items="GrupoControllers" item-text="Nome" item-value="Id" v-model="Model.GrupoController" label="Grupo de Controller" dense outlined hide-details></v-autocomplete>
              <v-autocomplete v-else return-object :items="Controllers" item-text="Nome" item-value="Id" v-model="Model.Controller" label="Controller" dense outlined hide-details></v-autocomplete>
            </v-col>
          </v-row>

          <v-row dense style="height: 48px !important">
            <v-col col="2" md="2" lg="1" offset="0" offset-md="2" offset-lg="2">
              <v-switch hide-details inset label="Index" color="primary" v-model="Model.FlagIndex" :false-value="false" :true-value="true"></v-switch>
            </v-col>

            <v-col col="2" md="2" lg="1" offset="0">
              <v-switch hide-details inset label="Edit" color="primary" v-model="Model.FlagEdit" :false-value="false" :true-value="true"></v-switch>
            </v-col>

            <v-col col="2" md="2" lg="1" offset="0">
              <v-switch hide-details inset label="Save" color="primary" v-model="Model.FlagSave" :false-value="false" :true-value="true"></v-switch>
            </v-col>

            <v-col col="2" md="2" lg="1" offset="0">
              <v-switch hide-details inset label="Delete" color="primary" v-model="Model.FlagDelete" :false-value="false" :true-value="true"></v-switch>
            </v-col>
          </v-row>

          <v-row dense :style="this.AllowAll">
            <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
              <v-row>
                <v-col>
                  <v-btn block @click="ShowConfirmaExcluir = true" color="error" v-if="this.CanDelete && Model.Id != 0">Excluir Permissão</v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-col>
                  <v-btn block @click="Index()" color="warning">Cancelar</v-btn>
                </v-col>
                <v-col>
                  <v-btn block @click="ShowConfirmaSalvar = true" color="primary" v-if="this.CanEdit">Salvar</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </template>
    </v-card>

    <confirm Msg="Excluir?" :Show="ShowConfirmaExcluir" v-on:confirm="Delete(), (ShowConfirmaExcluir = false)" v-on:cancel="ShowConfirmaExcluir = false"></confirm>
    <confirm Msg="Salvar?" :Show="ShowConfirmaSalvar" v-on:confirm="Save(), (ShowConfirmaSalvar = false)" v-on:cancel="ShowConfirmaSalvar = false"></confirm>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PermissoesEdit",
  data() {
    return {
      ShowDialog: true,
      Model: {},
      Controllers: [],
      GrupoUsers: [],
      GrupoControllers: [],
      Usuarios: [],
      ShowConfirmaExcluir: false,
      ShowConfirmaSalvar: false,
      IsPermitted: 0,
      CanEdit: false,
      CanDelete: false,
      AllowAll: false,
      HideEdit: false,
      HideDelete: false,
    };
  },
  methods: {
    Edit(Id) {
      axios.get("/api/Sistema/Permissoes/Edit/".concat(Id)).then((response) => {
        let data = response.data.Data;
        if (data != null) {
          this.Model = data;
        }
      });
    },
    GetListas() {
      const instance = axios.create();
      const GetController = instance.get("/api/Lista/Controller");
      const GetGrupoUser = instance.get("/api/Lista/GrupoUser");
      const GetGrupoController = instance.get("/api/Lista/GrupoController");
      const GetUsuarios = instance.get("/api/Lista/Usuarios");

      axios
        .all([GetController, GetGrupoUser, GetUsuarios, GetGrupoController])
        .then(
          axios.spread((...responses) => {
            responses[0].data.forEach((item) => {
              this.Controllers.push(item);
            });

            responses[1].data.forEach((item) => {
              this.GrupoUsers.push(item);
            });

            responses[2].data.forEach((item) => {
              this.Usuarios.push(item);
            });

            responses[3].data.forEach((item) => {
              this.GrupoControllers.push(item);
            });
          })
        )
        .catch((errors) => {});
    },
    Save() {
      axios({ method: "post", data: this.Model, url: "/api/Sistema/Permissoes/Save" })
        .then((response) => {
          this.Index();
          this.$toast.success("Dados Salvos");
        })
        .catch((error) => {
          //
        });
    },
    Delete() {
      axios({ method: "delete", data: this.Model, url: "/api/Sistema/Permissoes/Delete" })
        .then((response) => {
          this.Index();
        })
        .catch((error) => {
          //
        });
    },
    Index() {
      this.$router.push("/Sistema/Permissoes/Index");
    },
    AlteraPermissaoGrupo() {
      this.Model.Usuario = {};
      this.Model.GrupoUsuario = {};
    },
    AlteraPermissaoConroller() {
      this.Model.Controller = {};
      this.Model.GrupoController = {};
    },
    LoadPermission() {
      axios({ method: "get", data: null, url: "/api/Lista/GetAcesso" })
        .then((response) => {
          this.IsPermitted = response.data;
          this.HideEdit = this.$functions.EditPermitted(this.IsPermitted);
          this.CanEdit = Boolean(this.$functions.EditPermitted(this.IsPermitted, 1));
          this.AllowAll = this.$functions.AllowPermitted();
          this.HideDelete = this.$functions.DeletePermitted(this.IsPermitted);
          this.CanDelete = Boolean(this.$functions.DeletePermitted(this.IsPermitted, 1));
        })
        .catch((error) => {
          //
        });
    },
  },
  mounted: function () {
    let id = this.$route.params.id;

    this.GetListas();

    if (id != null) {
      this.Edit(id);
      this.LoadPermission();
    } else {
      this.$router.push("/Sistema/Permissoes/Index");
    }
  },
};
</script>
