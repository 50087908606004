<template>
  <div class="bordaappbar">
    <v-data-table class="elevation-1 row-pointer" :headers="Header" mobile-breakpoint="1" :items="GridData" :item-class="RowClass" :sort-by.sync="Pagination.sortBy" :must-sort="true" :sort-desc.sync="Pagination.sortDesc" item-key="Id" :options.sync="Pagination" :server-items-length="Pagination.itemsLength" :footer-props="{ itemsPerPageOptions: RowsPerPageItems }" v-on:update:page="Index()" v-on:update:items-per-page="Index()" v-on:update:sort-by="Index()" v-on:update:sort-desc="Index()">
      <template v-slot:top>
        <v-toolbar flat>
          <v-col>
            <v-autocomplete  :items="Empresas" item-text="Nome" item-value="Id" v-model="Pagination.Filtro.Empresa" label="Empresa" @change="Index()" clearable dense outlined hide-details ></v-autocomplete>
          </v-col>
          <v-col>
            <v-text-field @click:append="Index" dense outlined append-icon="mdi-magnify" label="Procurar" v-model="Pagination.Filtro.Busca" single-line hide-details @keyup.native.enter="Index()"></v-text-field>
          </v-col>

          <v-spacer></v-spacer>
          <v-btn to="/Ata/Edit/0" color="primary" v-if="CanEdit">
            Novo
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.DataAlteracao`]="{ item }">
        <span class="text-no-wrap">
          {{ item.DataAlteracao | dateTimeBR }}
        </span>
      </template>

      <template v-slot:[`item.Action`]="{ item }">
        <v-icon @click="Edit(item.Id)" color="teal">mdi-pencil</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AtaIndex",
  data() {
    return {
      GridData: [],
      Empresas: [],
      Header: [
        { text: "Id", value: "Id", sortable: false },
        { text: "Nome", value: "Nome", sortable: true },
        { text: "Tipo", value: "Tipo.Nome", sortable: true },
        { text: "Empresa", value: "Empresa.Nome", sortable: true },
        { text: "Número", value: "Numero", sortable: false },
        { text: "Última Alteração", value: "DataAlteracao", sortable: false },
        { text: "", value: "Action", sortable: false },
      ],
      RowsPerPageItems: [5, 10, 20, 30, 40, 50, 100, 200],
      Pagination: {
        page: 1,
        itemsPerPage: 100,
        pageCount: 1,
        itemsLength: 0,
        sortBy: ["Nome"],
        sortDesc: [false],
        Filtro: {
          Busca: "",
          Empresa: 0,
        },
      },
      IsPermitted: 0,
      CanEdit: false,
      CanDelete: false,
      AllowAll: false,
      HideEdit: false,
      HideDelete: false,
    };
  },
  methods: {
    Edit(Id) {
      this.$router.push("/Ata/Edit/".concat(Id));
    },
    Index() {
      axios.post("/api/Ata/Index", this.Pagination).then((response) => {
        this.GridData = response.data.Data;
        this.Pagination = response.data.Pagination;
        this.LoadPermission();
      });
    },
    RowClass(item) {
      if (item.Ativo == false) {
        return "rowdisabled";
      }
    },
    GetListas() {
      const instance = axios.create();
      const GetEmpresas = instance.get("/api/Lista/Empresa");

      axios
        .all([GetEmpresas])
        .then(
          axios.spread((...responses) => {
            responses[0].data.forEach((item) => {
              this.Empresas.push(item);
            });
          })
        )
        .catch((errors) => {});
    },
    LoadPermission() {
      axios({ method: "get", data: null, url: "/api/Lista/GetAcesso" })
        .then((response) => {
          this.IsPermitted = response.data;
          this.HideEdit = this.$functions.EditPermitted(this.IsPermitted);
          this.CanEdit = Boolean(this.$functions.EditPermitted(this.IsPermitted, 1));
          this.AllowAll = this.$functions.AllowPermitted();
          this.HideDelete = this.$functions.DeletePermitted(this.IsPermitted);
          this.CanDelete = Boolean(this.$functions.DeletePermitted(this.IsPermitted, 1));
        })
        .catch((error) => {
          //
        });
    },
  },
  mounted: function () {
    this.GetListas();
    this.Index();
    this.LoadPermission();
  },
};
</script>
