import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import confirm from "./components/Confirm"
import loadingEdit from "./components/LoadingEdit"
import messageshow from "./components/MessageShow"
import dtPicker from "./components/DtPicker";
import hrPicker from "./components/HrPicker";
import VueTheMask from 'vue-the-mask';
import moment from 'moment';
import VueMoment from 'vue-moment';
import VueI18n from 'vue-i18n';
import { VTextField } from 'vuetify/lib'
import VCurrencyField from 'v-currency-field';
import VueNumerals from 'vue-numerals';
import functions from "./functions";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import Tooltip from "vue-directive-tooltip";
import "vue-directive-tooltip/dist/vueDirectiveTooltip.css";

Vue.config.productionTip = false;

Vue.use(Toast, {
  transition: "Vue-Toastification__slideBlurred", //"Vue-Toastification__bounce", //"Vue-Toastification__fade"
  maxToasts: 20,
  newestOnTop: true,
  position: "top-right",
  timeout: 600 * 7,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false,

  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter((t) => t.type === toast.type).length !== 0) {
      // Returning false discards the toast - discard duplicates
      // return false;
    }
    // You can modify the toast if you want
    return toast;
  },
});

Vue.use(VueTheMask);
Vue.use(VueMoment);
Vue.use(VueI18n);
Vue.use(VueNumerals, { locale: "pt-br" });

Vue.use(Tooltip, {
  delay: 200,
  placement: "right",
  class: "tooltip-custom",
  triggers: ["hover"],
  offset: 0,
});

Vue.component("dtPicker", dtPicker);
Vue.component("hrPicker", hrPicker);
Vue.component("confirm", confirm);
Vue.component("messageshow", messageshow);
Vue.component("loadingEdit", loadingEdit);
Vue.component("moment", moment);

const Vuefunctions = {
  install() {
    Vue.functions = functions;
    Vue.prototype.$functions = functions;
  },
};
Vue.use(Vuefunctions);

Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, {
    locale: 'pt-BR',
    decimalLength: 2,
    autoDecimalMode: true,
    min: null,
    max: null,
    defaultValue: 0,
    valueAsInteger: false,
    allowNegative: false
})

const numberFormats = {
    'pt-BR': {
        currency: { style: 'currency', currency: 'BRL' },
        decimal: { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 },
        percent: { style: 'percent', useGrouping: false }
    }
}

const dateTimeFormats = {
    'pt-BR': {
        short: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour12: false
        },
        long: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            weekday: 'long',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false
        }
    }
}

const i18n = new VueI18n({
    locale: 'pt-BR',
    numberFormats,
    dateTimeFormats
});

const dateTimeBR = function (str) {
    if (str != null) {
        return moment(str).format("DD/MM/YYYY HH:mm:ss");
    }
    return "";
};

const dateBR = function (str) {
    if (str != null) {
        return moment(str).format("DD/MM/YYYY");
    }
    return "";
};

const timeBR = function (str) {
    if (str != null) {
        return moment(str).format("HH:mm:ss");
    }
    return "";
};

const currencyBR = function (str) {
    if (typeof str == "number") {
        var formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        });

        return formatter.format(str)
    }
    return "";
};

const currency = function (str, cod) {
    if (typeof str == "number") {
        var formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: cod
        });

        return formatter.format(str)
    }
    return "";
};

const replaceStr = function (str, oldStr, newStr) {
    if (str != null) {
        var nova = str.replace(oldStr, newStr);
        return nova;
    }
    return "";
};


const filters = {
    dateTimeBR,
    dateBR,
    timeBR,
    currencyBR,
    currency,
    replaceStr
};

Object.keys(filters).forEach(k => Vue.filter(k, filters[k]));

new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')