<template>
  <v-flex xs12 sm12 md12>
    <v-dialog ref="dialog" v-model="ShowModal" :return-value.sync="data" width="290px">
      <template v-slot:activator="{ on }">
        <v-text-field outlined dense hide-details :readonly="readonly" :label="label" v-model="computedDateFormatted" v-mask="'##/##/####'" ref="DataInput" @focus="$event.target.select()" @keydown.enter="returnEnter()" >
          <template v-slot:prepend-inner>
            <v-icon :disabled="readonly" tabindex="-1" @click="(ShowModal = !ShowModal), (on)">
              mdi-calendar
            </v-icon>
          </template>
        </v-text-field>
      </template>
      <v-date-picker reactive show-current :readonly="readonly" v-model="data" locale="pt-br" tabindex="-1" scrollable>
        <v-btn outlined color="orange" @click="ShowModal = !ShowModal">Voltar</v-btn>
        <v-spacer></v-spacer>
        <v-btn outlined color="primary" @click="save(data)">OK</v-btn>
      </v-date-picker>
    </v-dialog>
  </v-flex>

</template>

<script>
export default {
  name: 'DtPicker',
  data: function () {
    return {
      data: '',
      ShowModal: false
    }
  },
  props: {
    value: {
      type: String
    },
    label: {
      type: String
    },
    show: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    returnFalse() {
      this.$emit('cancel')
    },
    returnTrue() {
      this.$emit('confirm')
    },
    formatDateBR(date) {
      if (!date || date.length < 10) return '';
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    formatDateUS(date) {
      if (!date || date.length < 10) return '';
      const [day, month, year] = date.substring(0, 10).split('/');
      return `${year.padStart(4, '0')}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    save(dado) {
      this.$refs.dialog.save(dado);
      this.$emit('input', dado );
    },
    returnEnter() {
			this.$emit('keyenter')
		}
  },
  computed: {
    computedDateFormatted: {
      get() {
        if (this.data != null && this.data.length >= 10)
          return this.formatDateBR(this.data);
        else
          return ''
      },
      set(newValue) {
        if (newValue != null && newValue.length >= 10) {
          this.$emit('input', (this.formatDateUS(newValue) != '') ? this.formatDateUS(newValue) : '');
          this.data = this.formatDateUS(newValue)
        }else if (newValue == '')
        {
          this.$emit('input', null);
          this.data = null
        }
      }
    }
  },
  watch: {    
    value: {
      immediate: true, 
      handler (newVal, oldVal) {
        if (newVal != null && newVal.length >= 10)
          this.data = newVal.substring(0, 10);
        else
          this.data = null;
      }
    }
  }
}
</script>