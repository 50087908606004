<template>
  <div :style="HideEdit">
    <v-card>
      <loadingEdit v-if="this.$store.getters.showLoading"></loadingEdit>
      <template v-else>
        <v-card-text class="pl-1 pr-1">
          <v-col cols="12" offset="0">
            <v-card>
              <v-app-bar color="primary" dense>
                <v-toolbar-title class="white--text">Empresa</v-toolbar-title>
              </v-app-bar>
              <v-card-text class="bordaappbar">
                <v-row dense>
                  <v-col cols="10" md="2" lg="2" offset="0" offset-md="1" offset-lg="1">
                    <v-text-field outlined dense hide-details label="Id" v-model="Model.Id" readonly style="background-color: #d1d1d1"></v-text-field>
                  </v-col>
                  <v-col col="6" md="3" lg="1">
                    <v-switch hide-details inset label="Ativo" color="primary" v-model="Model.FlagAtivo" :false-value="false" :true-value="true"></v-switch>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="6" md="4" lg="4" offset="0" offset-md="1" offset-lg="1">
                    <v-text-field outlined dense hide-details label="Nome/Razão" v-model="Model.Nome" ref="txtNome"></v-text-field>
                  </v-col>
                  <v-col cols="6" md="3" lg="3">
                    <v-text-field outlined dense hide-details label="CNPJ" v-mask="['##.###.###/####-##']" v-model="Model.CPFCNPJ"></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col col="6" md="5" lg="5" offset="0" offset-md="1" offset-lg="1">
                    <v-text-field outlined dense hide-details label="E-Mail" v-model="Model.Email"></v-text-field>
                  </v-col>
                  <v-col col="6" md="5" lg="5">
                    <v-text-field outlined dense hide-details label="Telefone" v-mask="['(##) ####-####', '(##) #####-####']" :masked="false" v-model="Model.Telefone"></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col col="6" md="5" lg="5" offset="0" offset-md="1" offset-lg="1">
                    <v-text-field outlined dense hide-details label="Endereço" v-model="Model.Endereco"></v-text-field>
                  </v-col>
                  <v-col col="6" md="5" lg="5">
                    <v-text-field outlined dense hide-details label="Número" v-model="Model.Numero"></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col col="6" md="4" lg="4" offset="0" offset-md="1" offset-lg="1">
                    <v-text-field outlined dense hide-details label="Complemento" v-model="Model.Complemento"></v-text-field>
                  </v-col>
                  <v-col col="6" md="3" lg="3">
                    <v-text-field outlined dense hide-details label="Bairro" v-model="Model.Bairro"></v-text-field>
                  </v-col>
                  <v-col col="6" md="3" lg="3">
                    <v-text-field outlined dense hide-details label="CEP" v-mask="'#####-###'" v-model="Model.CEP"></v-text-field>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col col="6" md="4" lg="4" offset="0" offset-md="1" offset-lg="1">
                    <v-text-field outlined dense hide-details label="Cidade" v-model="Model.Cidade"></v-text-field>
                  </v-col>
                  <v-col col="6" md="3" lg="3">
                    <v-select outlined dense hide-details :items="UFs" item-text="UF" item-value="UF" label="UF"  v-model="Model.UF" />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col col="12" md="10" lg="10" offset="0" offset-md="1" offset-lg="1">
                    <v-textarea outlined dense hide-details rows="3" label="Observação" v-model="Model.Observacao"></v-textarea>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" offset="0">
                    <v-data-table class="elevation-1 row-pointer bordagrid" mobile-breakpoint="1" :headers="HeaderUsuarios" @click:row="RowUsuarioClick" :items="Model.Usuarios" hide-default-footer disable-pagination disabled-filtering>
                      <template v-slot:top>
                        <v-toolbar dense flat>
                          <v-toolbar-title>Usuários</v-toolbar-title>
                          <v-divider class="mx-1" inset vertical></v-divider>
                          <v-spacer></v-spacer>
                          <!-- <v-btn @click="AddUsuario()" color="primary">Novo Usuários <v-icon>mdi-plus</v-icon> </v-btn> -->
                        </v-toolbar>
                        <hr />
                      </template>

                      <template v-slot:[`item.FlagAtivo`]="{ item }">
                        <span class="text-no-wrap">
                          <v-icon :color="item.FlagAtivo == true ? 'success' : 'error'">{{ item.FlagAtivo == true ? "mdi-check-circle" : "mdi-close-circle" }}</v-icon>
                        </span>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-row class="mt-10" :style="this.AllowAll">
            <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
              <v-row>
                <v-col>
                  <v-btn block @click="ShowConfirmaExcluir = true" color="error" v-if="this.CanDelete && Model.Id != 0">Excluir Empresa</v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-col>
                  <v-btn block @click="Index()" color="warning">Cancelar</v-btn>
                </v-col>
                <v-col>
                  <v-btn block @click="ShowConfirmaSalvar = true" color="primary" v-if="this.CanEdit">Salvar</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </template>
    </v-card>

    <v-dialog v-model="DialogEditUsuario" width="800">
      <v-card>
        <v-app-bar color="primary" dense>
          <v-toolbar-title class="white--text">Edição de Usuário</v-toolbar-title>
        </v-app-bar>
        <v-card-text>
          <v-container class="pt-10">
            <template>
              <v-row dense>
                <v-col cols="12">
                  <v-switch hide-details inset label="Ativo" color="primary" v-model="UsuarioSelecionadoEdit.FlagAtivo" :false-value="false" :true-value="true"></v-switch>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field outlined dense hide-details label="Nome" v-model="UsuarioSelecionadoEdit.Nome" ref="txtNome"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field outlined dense hide-details label="E-Mail" v-model="UsuarioSelecionadoEdit.Email" ref="txtNome"></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-textarea outlined dense hide-details rows="3" label="Observação" v-model="UsuarioSelecionadoEdit.Observacao"></v-textarea>
                </v-col>
              </v-row>
              <v-card-actions class="pt-10">
                <v-spacer></v-spacer>
                <v-btn x-large color="warning" @click="DialogEditUsuario = false">Cancelar</v-btn>
                <v-btn x-large color="primary" @click="RowUsuarioSave()">Salvar</v-btn>
              </v-card-actions>
            </template>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <confirm Msg="Excluir?" :Show="ShowConfirmaExcluir" v-on:confirm="Delete(), (ShowConfirmaExcluir = false)" v-on:cancel="ShowConfirmaExcluir = false"></confirm>
    <confirm Msg="Salvar?" :Show="ShowConfirmaSalvar" v-on:confirm="Save(), (ShowConfirmaSalvar = false)" v-on:cancel="ShowConfirmaSalvar = false"></confirm>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "UsuariosEdit",
  data() {
    return {
      ShowDialog: true,
      Model: {
        Cliente: {},
        Empresa: {},
        Colaborador: {},
        Banco: {
          Numero: 0,
        },
      },
      NivelAcesso: [],
      Empresas: [],
      Empresa: {},
      ShowConfirmaExcluir: false,
      ShowConfirmaSalvar: false,
      DialogEditUsuario: false,
      UsuarioSelecionado: null,
      UsuarioSelecionadoEdit: {
        FlagAtivo: false,
        Nome: "",
        Email: "",
        Observacao: "",
      },
      HeaderUsuarios: [
        { text: "Usuário", value: "Nome", sortable: false },
        { text: "E-Mail", value: "Email", sortable: false },
        { text: "Login", value: "Login", sortable: false },
        { text: "Ativo", value: "FlagAtivo", sortable: false },
        { text: "", value: "Action", sortable: false, width: "70px", align: "center" },
      ],
      UFs: [
        { UF: "AC", Nome: "Acre" },
        { UF: "AL", Nome: "Alagoas" },
        { UF: "AP", Nome: "Amapá" },
        { UF: "AM", Nome: "Amazonas" },
        { UF: "BA", Nome: "Bahia" },
        { UF: "CE", Nome: "Ceará" },
        { UF: "DF", Nome: "Distrito Federal" },
        { UF: "ES", Nome: "Espírito Santo" },
        { UF: "GO", Nome: "Goías" },
        { UF: "MA", Nome: "Maranhão" },
        { UF: "MT", Nome: "Mato Grosso" },
        { UF: "MS", Nome: "Mato Grosso do Sul" },
        { UF: "MG", Nome: "Minas Gerais" },
        { UF: "PA", Nome: "Pará" },
        { UF: "PB", Nome: "Paraíba" },
        { UF: "PR", Nome: "Paraná" },
        { UF: "PE", Nome: "Pernambuco" },
        { UF: "PI", Nome: "Piauí" },
        { UF: "RJ", Nome: "Rio de Janeiro" },
        { UF: "RN", Nome: "Rio Grande do Norte" },
        { UF: "RS", Nome: "Rio Grande do Sul" },
        { UF: "RO", Nome: "Rondônia" },
        { UF: "RR", Nome: "Roraíma" },
        { UF: "SC", Nome: "Santa Catarina" },
        { UF: "SP", Nome: "São Paulo" },
        { UF: "SE", Nome: "Sergipe" },
        { UF: "TO", Nome: "Tocantins" },
      ],
      IsPermitted: 0,
      CanEdit: false,
      CanDelete: false,
      AllowAll: false,
      HideEdit: false,
      HideDelete: false,
    };
  },
  methods: {
    Edit(Id) {
      axios.get("/api/Cadastro/Empresa/Edit/".concat(Id)).then((response) => {
        let data = response.data.Data;
        if (data != null) {
          this.Model = data;
        }
        if (Id == 0) this.Model.FlagAtivo = true;
      });
    },
    Save() {
      axios({ method: "post", data: this.Model, url: "/api/Cadastro/Empresa/Save" })
        .then((response) => {
          this.Index();
          this.$toast.success("Dados Salvos");
        })
        .catch((error) => {
          //
        });
    },
    Delete() {
      axios({ method: "delete", data: this.Model, url: "/api/Cadastro/Empresa/Delete" })
        .then((response) => {
          this.Index();
        })
        .catch((error) => {
          //
        });
    },
    Index() {
      this.$router.push("/Cadastro/Empresa/Index");
    },
    GetListas() {
      const instance = axios.create();

      const GetNivelAcesso = instance.get("/api/Lista/NivelAcesso");
      const GetEmpresa = instance.get("/api/Lista/Empresa");

      axios
        .all([GetNivelAcesso, GetEmpresa])
        .then(
          axios.spread((...responses) => {
            responses[0].data.forEach((item) => {
              this.NivelAcesso.push(item);
            });

            responses[1].data.forEach((item) => {
              this.Empresas.push(item);
            });
          })
        )
        .catch((errors) => {});
    },
    AddEmpresa() {
      if (!this.Empresa) {
        return;
      }

      let colab = this.Model.Cliente.Empresas.find((x) => x.Empresa.Id == this.Empresa.Id);
      if (colab) {
        return;
      }

      this.Model.Cliente.Empresas.push({ Id: 0, Empresa: this.Empresa });
    },
    DelEmpresa(Item) {
      this.Model.Cliente.Empresas.splice(this.Model.Cliente.Empresas.indexOf(Item), 1);
    },
    RowUsuarioClick(item) {
      this.UsuarioSelecionadoEdit = JSON.parse(JSON.stringify(item));
      this.UsuarioSelecionado = item;
      this.DialogEditUsuario = true;
    },

    RowUsuarioSave() {
      this.Model.Usuarios.splice(this.Model.Usuarios.indexOf(this.UsuarioSelecionado), 1, this.UsuarioSelecionadoEdit);
      this.UsuarioSelecionado = null;
      this.DialogEditUsuario = false;
    },
    AddUsuario() {
      this.DialogEditUsuario = true;
    },
    DelUsuario(item) {},
    LoadPermission() {
      axios({ method: "get", data: null, url: "/api/Lista/GetAcesso" })
        .then((response) => {
          this.IsPermitted = response.data;
          this.HideEdit = this.$functions.EditPermitted(this.IsPermitted);
          this.CanEdit = Boolean(this.$functions.EditPermitted(this.IsPermitted, 1));
          this.AllowAll = this.$functions.AllowPermitted();
          this.HideDelete = this.$functions.DeletePermitted(this.IsPermitted);
          this.CanDelete = Boolean(this.$functions.DeletePermitted(this.IsPermitted, 1));
        })
        .catch((error) => {
          //
        });
    },
  },
  mounted: function () {
    let id = this.$route.params.id;
    if (id != null) {
      this.GetListas();
      this.Edit(id);
      this.LoadPermission();
    } else {
      this.$router.push("/Cadastro/Empresa/Index");
    }
  },
};
</script>
