<template>
  <div :style="HideEdit">
    <v-card>
      <loadingEdit v-if="this.$store.getters.showLoading"></loadingEdit>
      <template v-else>
        <v-card-text class="pl-1 pr-1">
          <v-row dense>
            <v-col col="4" md="2" lg="2" offset="0" offset-md="2" offset-lg="2">
              <v-text-field outlined dense hide-details label="Id" v-model="Model.Id" readonly style="background-color: #d1d1d1"></v-text-field>
            </v-col>
            <v-col col="12" md="6" lg="6">
              <v-switch hide-details inset label="Registro de Acões Nominativas" color="primary" v-model="Model.FlagNormativa" :false-value="false" :true-value="true"></v-switch>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col col="12" md="6" lg="6" offset="0" offset-md="2" offset-lg="2">
              <v-text-field outlined dense hide-details label="Nome" v-model="Model.Nome" ref="txtNome"></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mt-10" :style="this.AllowAll">
            <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
              <v-row>
                <v-col>
                  <v-btn block @click="ShowConfirmaExcluir = true" color="error" v-if="this.CanDelete && Model.Id != 0">Excluir Tipo</v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-col>
                  <v-btn block @click="Index()" color="warning">Cancelar</v-btn>
                </v-col>
                <v-col>
                  <v-btn block @click="ShowConfirmaSalvar = true" color="primary" v-if="this.CanEdit">Salvar</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </template>
    </v-card>

    <confirm Msg="Excluir?" :Show="ShowConfirmaExcluir" v-on:confirm="Delete(), (ShowConfirmaExcluir = false)" v-on:cancel="ShowConfirmaExcluir = false"></confirm>
    <confirm Msg="Salvar?" :Show="ShowConfirmaSalvar" v-on:confirm="Save(), (ShowConfirmaSalvar = false)" v-on:cancel="ShowConfirmaSalvar = false"></confirm>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AtaTipoEdit",
  data() {
    return {
      ShowDialog: true,
      Model: {},
      ShowConfirmaExcluir: false,
      ShowConfirmaSalvar: false,
    };
  },
  methods: {
    Edit(Id) {
      axios.get("/api/Cadastro/AtaTipo/Edit/".concat(Id)).then((response) => {
        let data = response.data.Data;
        if (data != null) {
          this.Model = data;
        }
      });
    },
    Save() {
      axios({ method: "post", data: this.Model, url: "/api/Cadastro/AtaTipo/Save" })
        .then((response) => {
          this.Index();
          this.$toast.success("Dados Salvos");
        })
        .catch((error) => {
          //
        });
    },
    Delete() {
      axios({ method: "delete", data: this.Model, url: "/api/Cadastro/AtaTipo/Delete" })
        .then((response) => {
          this.Index();
        })
        .catch((error) => {
          //
        });
    },
    Index() {
      this.$router.push("/Cadastro/AtaTipo/Index");
    },
    LoadPermission() {
      axios({ method: "get", data: null, url: "/api/Lista/GetAcesso" })
        .then((response) => {
          this.IsPermitted = response.data;
          this.HideEdit = this.$functions.EditPermitted(this.IsPermitted);
          this.CanEdit = Boolean(this.$functions.EditPermitted(this.IsPermitted, 1));
          this.AllowAll = this.$functions.AllowPermitted();
          this.HideDelete = this.$functions.DeletePermitted(this.IsPermitted);
          this.CanDelete = Boolean(this.$functions.DeletePermitted(this.IsPermitted, 1));
        })
        .catch((error) => {
          //
        });
    },
  },
  mounted: function () {
    let id = this.$route.params.id;
    if (id != null) {
      this.Edit(id);
      this.LoadPermission();
      this.$refs.txtNome.focus();
    } else {
      this.$router.push("/Cadastro/AtaTipo/Index");
    }
  },
};
</script>
