<style>
.v-list-item {
  background: white;
  border-radius: 0px !important;
  color: black;
}
.v-list-item:hover {
  background: #1976d2;
  color: white;
}
.v-list-group-title:hover {
  background: #1976d2;
  color: #ffff;
}
.v-list-item--active {
  background: #1976d2;
  color: white !important;
}
.v-list-group__header.v-list-item--active {
  /* background: #1976d2; */
  /* color: white; */
  background: white !important;
  color: #000000 !important;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.v-list-group__item.v-list-item--active {
  background: #1976d2;
  color: white;
}
.SelectedTile:hover {
  background: white !important;
  color: #000000 !important;
}
.SelectedTile::selection {
  background: white !important;
  color: #000000 !important;
}
.SelectedTile:active {
  background: white !important;
  color: #ffff !important;
}
</style>

<template>
  <span>
    <v-overlay v-model="overlay" persistent :opacity="0.98" />
    <v-list nav style="padding-left: 0px !important" :ripple="false">
      <template>
        <span v-for="(menu, index) in GetMenu(0)" :key="index" style="padding-left: 0px !important">
          <v-divider v-if="menu.Divider && HasSubMenu(menu.Id)"></v-divider>

          <!-- GRUPO -->
          <v-list-group v-if="HasSubMenu(menu.Id)" class="SelectedTile" nudge-bottom="30">
            <template v-slot:activator>
              <v-list-item-icon style="margin-right: 10px">
                <v-icon>{{ menu.Icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ menu.ItemMenu }}</v-list-item-title>
            </template>

            <!-- SUBGRUPO -->
            <v-list-item dense v-for="submenu in GetSubMenu(menu.Id)" :key="submenu.Id" :to="submenu.Link" class="pl-6 pt-0 pb-0 mt-0 mb-0 SelectedSubTile">
              <v-list-item-icon style="margin-right: 10px">
                <v-icon>{{ submenu.Icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ submenu.ItemMenu }}</v-list-item-title>
            </v-list-item>
          </v-list-group>

          <!-- DIVIDER -->
          <v-divider v-if="menu.Divider && !HasSubMenu(menu.Id)"/>

          <!-- MENU -->
          <v-list-item v-if="!HasSubMenu(menu.Id)" :to="menu.Link" :key="menu.Id" style="padding-left: 0px !important; margin-left: 0px" >
            <!-- :style="menu.ItemMenu.toLowerCase()=='sair'?'position: absolute;bottom: 0;width: 100%;':''" -->
            <v-list-item-icon style="margin-left: 5px; margin-right: 10px">
              <v-icon>{{ menu.Icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ menu.ItemMenu }}</v-list-item-title>
          </v-list-item>
        </span>
      </template>
    </v-list>
  </span>

</template>

<script>
import axios from "axios";

export default {
  name: "Menu",
  data: function () {
    return {
      GridData: [],
      GridDataTemp: [],
      overlay: false,
    };
  },
  computed: {},
  methods: {
    registerMenuId(menuId) {
      this.$store.commit("SET_MENUID", menuId);
    },
    unselectGroup() {
      this.menu = null;
    },
    unselectGroup2() {
      let x = 1;
    },
    Index() {
      const Url = "/api/Lista/SistemaMenus";
      axios
        .get(Url)
        .then((response) => {
          //Pega os dado do Grid
          this.GridData = response.data;
        })
        .catch((err) => console.error(err));
    },
    GetMenu(nivel) {
      let ret = this.GridData.filter((x) => x.IdMenuPai == nivel);
      return ret;
    },
    HasSubMenu(nivel) {
      let ret = this.GridData.filter((x) => x.IdMenuPai == nivel);
      if (ret.length == 0) {
        return false;
      }
      return true;
    },
    GetSubMenu(nivel) {
      let ret = this.GridData.filter((x) => x.IdMenuPai == nivel);
      return ret;
    },
  },
  mounted: function () {
    this.Index();
  },
};
</script>
