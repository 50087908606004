<template>
  <v-flex xs12 sm12 md12>
    <v-dialog persistent v-model="showDialog" @keydown.enter="returnTrue()" @keydown.esc="returnFalse()" width="420px">
      <v-card>
        <v-toolbar dense flat outlined color="primary">
          <v-toolbar-title class="white--text">
            {{ Msg }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row class="mt-5 mb-5">
            <span style="color: #1976d2">
            {{ MsgText ? MsgText : "Deseja continuar com a operação ?" }}
            </span>
          </v-row>
          <v-row class="mt-10">
            <v-spacer></v-spacer>
            <v-btn color="error" outlined @click="returnFalse" width="35%">Não</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" outlined @click="returnTrue" width="35%">Sim</v-btn>
            <v-spacer></v-spacer>
          </v-row>
        </v-card-text>
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined @click="returnFalse" width="35%">Não</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" outlined @click="returnTrue" width="35%">Sim</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </v-flex>
</template>

<script>
export default {
  name: "confirm",
  props: ["MsgText", "Show", "Msg"],
  methods: {
    returnFalse() {
      this.$emit("cancel");
    },
    returnTrue() {
      this.$emit("confirm");
    },
  },
  computed: {
    showDialog() {
      return this.Show;
    },
  },
};
</script>
