<template>
  <div>
    <v-card height="100%" width="100%">
      <loadingEdit v-if="this.$store.getters.showLoading"></loadingEdit>
      <template v-else>
        <v-app-bar color="primary" dense>
          <v-toolbar-title class="title white--text"> Configurações </v-toolbar-title>
        </v-app-bar>

        <v-card-text class="pl-1 pr-1">
          <v-tabs v-model="Tab">
            <v-tab href="#Geral"> Geral </v-tab>
            <v-tab href="#EMAIL"> EMAIL </v-tab>
            <v-tab href="#PDF"> PDF </v-tab>
            <v-tab href="#AWS"> AWS </v-tab>
          </v-tabs>

          <v-tabs-items v-model="Tab">
            <v-tab-item transition="false" value="Geral" class="pa-1" style="min-height: 30vh" :style="HideEdit">
              <br />

              <v-row dense>
                <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                  <v-text-field hide-details dense outlined prepend-inner-icon="mdi-folder-outline" label="Caminho Upload Temp." v-model="Model.CaminhoUploadTemp"></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                  <v-text-field hide-details dense outlined prepend-inner-icon="mdi-folder-outline" label="Caminho Arquivos Ata Temp." v-model="Model.CaminhoArquivosAta"></v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item transition="false" value="EMAIL" class="pa-1" style="min-height: 30vh" :style="HideEdit">
              <br />
              <row dense>
                <v-col>
                  <fieldset class="fieldsetStyle">
                    <legend class="fieldsetLegendStyle">Dados de SMTP</legend>
                    <v-row dense>
                      <v-col col="10" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                        <v-text-field hide-details dense outlined prepend-inner-icon="mdi-email-fast-outline" label="Servidor SMTP" v-model="Model.EmailServidorSmtp"></v-text-field>
                      </v-col>
                      <v-col col="10" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                        <v-text-field hide-details dense outlined prepend-inner-icon="mdi-network-outline" label="Porta SMTP" v-model="Model.EmailPortaSmtp"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col col="10" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                        <v-text-field hide-details dense outlined prepend-inner-icon="mdi-format-text" label="Nome do Remetente" v-model="Model.EmailNomeRemetente"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                        <v-text-field hide-details dense outlined prepend-inner-icon="mdi-account-outline" label="Login SMTP" v-model="Model.EmailLogin"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                        <v-text-field hide-details dense outlined prepend-inner-icon="mdi-lock-outline" label="Senha SMTP" type="password" v-model="Model.EmailSenha"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                        <v-text-field hide-details dense outlined prepend-inner-icon="mdi-email-fast-outline" label="E-Mail From" v-model="Model.EmailEndereco"></v-text-field>
                      </v-col>
                    </v-row>
                  </fieldset>
                </v-col>
              </row>

              <row dense>
                <v-col>
                  <fieldset class="fieldsetStyle">
                    <legend class="fieldsetLegendStyle">Assunto das Mensagens</legend>
                    <v-row dense>
                      <v-col col="10" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                        <v-text-field hide-details dense outlined prepend-inner-icon="mdi-format-text" label="Assunto para Novo Usuário" v-model="Model.EmailAssuntoNovoUsuarioSistema"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                        <v-text-field hide-details dense outlined prepend-inner-icon="mdi-format-text" label="Assunto para Novo Livro" v-model="Model.EmailAssuntoNovoLivroAta"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                        <v-text-field hide-details dense outlined prepend-inner-icon="mdi-format-text" label="Assunto para Recuperação de Senha"  v-model="Model.EmailAssuntoRecuperacaoSenha"></v-text-field>
                      </v-col>
                    </v-row>
                  </fieldset>
                </v-col>
              </row>
            </v-tab-item>

            <v-tab-item transition="false" value="PDF" class="pa-1" style="min-height: 30vh" :style="HideEdit">
              <br />

              <v-row dense>
                <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                  <v-text-field hide-details dense outlined prepend-inner-icon="mdi-format-text" label="Rodape Linha 1 PDF" v-model="Model.RodapeLinha1PDF"></v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item transition="false" value="AWS" class="pa-1" style="min-height: 30vh" :style="HideEdit">
              <br />

              <v-row dense>
                <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                  <v-text-field hide-details dense outlined prepend-inner-icon="mdi-aws" label="AWS Access Key" v-model="Model.AWS_accessKey"></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                  <v-text-field hide-details dense outlined prepend-inner-icon="mdi-aws" label="AWS Secret Key" v-model="Model.AWS_secretKey"></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                  <v-text-field hide-details dense outlined prepend-inner-icon="mdi-aws" label="AWS Region" v-model="Model.AWS_region"></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                  <v-text-field hide-details dense outlined prepend-inner-icon="mdi-aws" label="AWS Bucket Name" v-model="Model.AWS_bucketName"></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                  <v-text-field hide-details dense outlined prepend-inner-icon="mdi-aws" label="AWS Default Folder" v-model="Model.AWS_defaultFolder"></v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
          <br />

          <v-row dense :style="this.AllowAll">
            <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
              <v-row>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-col></v-col>
                <v-col></v-col>
                <v-col>
                  <v-btn block @click="ShowConfirmaSalvar = true" color="primary" v-if="this.CanEdit">Salvar</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </template>
    </v-card>
    <confirm Msg="Salvar?" :Show="ShowConfirmaSalvar" v-on:confirm="Save(), (ShowConfirmaSalvar = false)" v-on:cancel="ShowConfirmaSalvar = false"></confirm>
  </div>
</template>

<style>
.fieldsetStyle {
  height: calc(100% - 2px);
  border: 1px solid #bed6f8;
  margin-left: 5px;
  padding: 10px 10px 10px 10px;
  margin-right: 0px;
}
.fieldsetLegendStyle {
  position: relative;
  top: 1px;
  border: 1px solid #bed6f8;
}
</style>

<script>
import axios from "axios";

export default {
  name: "Configuracoes",
  data() {
    return {
      Model: {},
      Tab: {},
      ShowDialog: true,
      ShowConfirmaSalvar: false,
      IsPermitted: 0,
      CanEdit: false,
      CanDelete: false,
      AllowAll: false,
      HideEdit: false,
      HideDelete: false,
    };
  },
  components: {},
  methods: {
    Index() {
      axios.post("/api/CadastroGeral/Configuracoes/Index").then((response) => {
        this.Model = response.data.Data;
        this.LoadPermission();
      });
    },
    Save() {
      axios({ method: "post", data: this.Model, url: "/api/CadastroGeral/Configuracoes/Save" })
        .then((response) => {})
        .catch((error) => {
          //
        });
    },
    LoadPermission() {
      axios({ method: "get", data: null, url: "/api/Lista/GetAcesso" })
        .then((response) => {
          this.IsPermitted = response.data;
          this.HideEdit = this.$functions.EditPermitted(this.IsPermitted);
          this.CanEdit = Boolean(this.$functions.EditPermitted(this.IsPermitted, 1));
          this.AllowAll = this.$functions.AllowPermitted();
          this.HideDelete = this.$functions.DeletePermitted(this.IsPermitted);
          this.CanDelete = Boolean(this.$functions.DeletePermitted(this.IsPermitted, 1));
        })
        .catch((error) => {
          //
        });
    },
  },
  mounted: function () {
    this.Index();
  },
};
</script>
