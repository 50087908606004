<template>
  <div>
    Auto-Logoff: {{Time}}
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'countdownlogoff',
  data: function () {
    return {
      Time: '',
      Secs: 0,
      Activated: false,
      Interval: null
    }
  },
  props: {
    Seconds: {
      type: Number,
      default: 300 // 5 min
    },
    Enabled: {
      type: Boolean,
      default: false
    },
    Page: {
      type: String,
      default: "/Logoff"
    }
  },
  methods: {
    CountDown() {
      if (this.Activated == true) {
        this.Secs--;

        var t = moment.duration(this.Secs, 'seconds');
        this.Time = `${(t.get('minutes')).toString().padStart(2,0)}:${(t.get('seconds')).toString().padStart(2,0)}`

        //Apenas para debug
        //console.log(this.Secs);

        if (this.Secs <= 0) {
          this.Activated = false;
          clearInterval(this.Interval);
          this.$router.push(this.Page);
        }
      }
    },
    setTimer(){
      this.Interval = setInterval(this.CountDown, 1000);
    },
    resetTimer(){
      clearInterval(this.Interval);
      //this.setTimer(); //Ajuste, comentando esta linha, para evitar a criação de uma segundo countdown , em teste
    }
  },
  watch: {
    //Este valor é alterado entre true e false, toda vez que um reuqisição ao backend e realizada
    //com isso
    '$store.state.CountDownLogoffReset': function () {
      this.Secs = this.Seconds;
    }
  },
  created() {
    this.Secs = this.Seconds;
    this.Activated = this.Enabled;   
    this.setTimer(); 
  },
  destroyed() {
    this.resetTimer();
  },
}
</script>