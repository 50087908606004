<template>
  <v-app>
    <v-navigation-drawer v-if="usrLogged" v-model="drawer" app class="d-print-none">
      <v-list-item class="px-2" two-lines>
        <v-list-item-content>
          <v-list-item-title class="black--text font-weight-bold title">FCM Legal</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item class="px-2">
        <v-list-item-title class="text-capitalize text-wrap">
          <center>
            {{ usrOpe.Nome }}
            <br />
            <span class="blue--text">
              <b>{{ usrOpe.IdEmpresa == null ? "USUÁRIO FCM" : usrOpe.Empresa.Nome }}</b>
            </span>
          </center>
        </v-list-item-title>
      </v-list-item>

      <v-divider></v-divider>

      <Menu></Menu>
    </v-navigation-drawer>

    <v-app-bar v-if="usrLogged && !ModoRelatorio" app fixed dense dark color="primary" class="d-print-none">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-app-bar-title>
        SISTEMA DE ATA
        <v-icon>mdi-chevron-right</v-icon>
        {{ TituloTela }}
      </v-app-bar-title>

      <v-spacer></v-spacer>

      <v-divider v-if="false" class="mx-4" vertical></v-divider>
      <!-- Contador regressivo logout -->
      <CountDownLogoff :Seconds="600" :Enabled="true" Page="/Logout"></CountDownLogoff>

      <v-divider class="mx-4" vertical></v-divider>

      <TrocaSenha v-tooltip="'Trocar Senha'"></TrocaSenha>

      <v-divider class="mx-4" vertical></v-divider>

      <v-btn fab small color="secondary" @click="ShowConfirmaSair = true" v-tooltip="'Sair do Sistema'">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <div id="dialog-holder">
        <v-container fluid>
          <router-view />
        </v-container>
      </div>
    </v-main>

    <confirm Msg="Sair do Sistema?" :Show="ShowConfirmaSair" v-on:confirm="Logout(), (ShowConfirmaSair = false)" v-on:cancel="ShowConfirmaSair = false"></confirm>

    <loading v-if="showLoading"></loading>
    <v-snackbar :color="snackbar.color" app top dense multi-line right v-model="snackbar.show" class="d-print-none">
      <section v-html="snackbar.msg"></section>
      <template v-slot:[`action`]="{ attrs }">
        <v-btn text v-on:click="snackbar.show = false" v-bind="attrs">
          <v-icon dark>mdi-close-box-outline</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<style>
.v-dialog--fullscreen {
  padding-top: 70px;
  border-radius: 0;
  margin: 0;
  height: 100%;
  position: fixed;
  overflow-y: auto;
  /* left: auto !important; */
}

.v-input--switch {
  margin-top: 4px !important;
}

.disable-style {
  color: rgba(0, 0, 0, 0.4) !important;
}

.disable-style .v-text-field__slot input {
  color: rgba(0, 0, 0, 0.4) !important;
}

.rowdisabled {
  background-color: #ffcdd2 !important;
  border-color: #ffcdd2 !important;
}

.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
  background-color: #a6bbdf76 !important;
}

.v-data-table__expanded.v-data-table__expanded__content > td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.v-data-table__expanded.v-data-table__expanded__content > .inner-data-table {
  box-shadow: none !important;
  background-color: #1c709a76 !important;
}

.v-data-table tbody tr {
  cursor: pointer !important;
}

/* https://coolors.co/palette/595959-7f7f7f-a5a5a5-cccccc-f2f2f2  */
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 14px !important;
  color: black !important;
  background-color: #cccccc !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 14px !important;
}

.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.03);
}
.v-data-table tbody tr.v-data-table__selected {
  background: rgba(25, 118, 210, 0.5) !important;
}
.v-data-table tbody tr.v-data-table__selected:hover {
  background: rgba(25, 118, 210, 0.55) !important;
}

.bordagrid {
  border: 2px solid black;
}

.bordaappbar {
  border: 2px solid #1976d2;
}

/* CSS para o selected do autocomplete */
.v-list-item.primary--text.v-list-item--active.v-list-item--link.theme--light.v-list-item--highlighted {
  background-color: #1976d2 !important;
  color: white !important;
}

/* CSS para o Tooltip */
.vue-tooltip.tooltip-custom {
  background-color: black;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: small;
}

.vue-tooltip.tooltip-custom .tooltip-arrow {
  border-color: black;
}
</style>

<script>
import axios from "axios";
import loading from "./components/Loading.vue";
import CountDownLogoff from "./components/CountDownLogoff.vue";
import TrocaSenha from "./components/TrocaSenha.vue";
import Menu from "./components/Menu.vue";

export default {
  name: "App",
  data: () => ({
    drawer: true,
    snackbar: {
      show: false,
      color: "info",
      msg: "",
    },
    Rotas: [],
    ShowConfirmaSair: false,
    PermissaoUsuario: -1,
    keyX: 0,
    NomeEmpresa: "",
  }),
  components: {
    loading: loading,
    CountDownLogoff: CountDownLogoff,
    Menu: Menu,
    TrocaSenha: TrocaSenha,
  },
  methods: {
    atualizaEmpresa() {
      if (this.NomeEmpresa == "") {
        this.NomeEmpresa = "USUÁRIO FCM";
        try {
          this.NomeEmpresa = this.$store.getters.usrOpe.Empresa.Nome;
        } catch {}
        return this.NomeEmpresa;
      }
    },
    msgBox(msg, num) {
      this.snackbar.show = true;
      this.snackbar.msg = msg;
    },
    Logout() {
      this.$router.push("/Logout");
    },
    mousemove(event) {
      this.$store.commit("SET_COUNTDOWNLOGOFFRESET", !this.$store.getters.CountDownLogoffReset);
    },
  },
  computed: {
    usrLogged() {
      return this.$store.getters.usrLogged;
    },
    ModoRelatorio() {
      return this.$store.getters.ModoRelatorio;
    },
    showLoading() {
      return this.$store.getters.showLoading;
    },
    usrOpe() {
      return this.$store.getters.usrOpe;
    },
    TituloTela() {
      return this.$store.getters.TituloTela;
    },
    Rota() {
      let itens = this.$route.path.split("/");
      itens[0] = "#";
      let rotas = [];
      itens.forEach((item) => {
        rotas.push({ text: this.$route.params.lenth > 0 ? this.$route.params[0] : item });
      });

      return rotas;
    },
  },
  created() {
    axios.interceptors.request.use(
      (config) => {
        this.$store.commit("SET_COUNTDOWNLOGOFFRESET", !this.$store.getters.CountDownLogoffReset);
        this.$store.commit("SET_LOADING", true);
        return config;
      },
      (error) => {
        this.$store.commit("SET_LOADING", false);
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        this.$store.commit("SET_LOADING", false);
        return response;
      },
      (error) => {
        switch (error.response.status) {
          case 401:
            window.location.href = "/Logout";
            break;

          case 403:
            window.location.href = "/Denied";
            break;

          case 500:
            this.$router.push({ name: "Error", params: { mensagem: error.response.data } });
            break;

          case 400:
            this.$toast.error(error.response.data.Msg ?? "Erro");
            break;

          default:
            {
              try {
                if (error.request.responseType === "blob" && error.response.data instanceof Blob && error.response.data.type && error.response.data.type.toLowerCase().indexOf("json") != -1) {
                  break;
                }

                if (error.response.data) {
                  if (error.response.data.Data.Msg) {
                    if (error.response.status == 400 && error.response.data.Data.Msg.indexOf("validation") != -1) {
                      this.$toast.error(error.response.data.errors);
                      break;
                    }
                  }
                }

                // if (error.response.status == 400) {
                //   this.$toast.error(error.response.data.Data.Msg ?? "Erro");
                // } else if (error.response.data.Data.Success == false) {
                //   this.$toast.error(error.response.data.Data.Msg ?? "Erro");
                // }
              } catch {}
            }
            break;
        }

        this.$store.commit("SET_LOADING", false);
        return Promise.reject(error);
      }
    );
  },
  mounted() {
    document.addEventListener("pointermove", this.mousemove.bind(this));
    document.addEventListener("keydown", this.mousemove.bind(this));
  },
  destroyed() {
    document.removeEventListener("pointermove", this.mousemove.bind(this));
    document.removeEventListener("keydown", this.mousemove.bind(this));
  },
};
</script>
