<template>
  <div>
    <v-col cols="12" xl="8" offset-xl="2">
      <v-data-table class="elevation-1 row-pointer" mobile-breakpoint="1" :headers="Header" :items="GridData" :item-class="RowClass" :sort-desc.sync="Pagination.sortDesc" item-key="Id" :options.sync="Pagination" :server-items-length="Pagination.itemsLength" :footer-props="{ itemsPerPageOptions: RowsPerPageItems }" v-on:update:page="Index()" v-on:update:items-per-page="Index()" v-on:update:sort-by="Index()" v-on:update:sort-desc="Index()">
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field dense outlined append-icon="mdi-magnify" label="Procurar" v-model="Pagination.Filtro.Busca" single-line hide-details @keyup.native.enter="Index()" @click:append="Index" ></v-text-field>
            &nbsp;&nbsp;
            <v-switch hide-details dense label="Mostrar Inativos" color="primary" v-model="Pagination.Filtro.Inativos" :false-value="false" :true-value="true" @change="Index()"></v-switch>
            <v-spacer></v-spacer>
            <v-btn to="/Sistema/Usuarios/Edit/0" color="primary" v-if="CanEdit">
              Nova
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:[`item.Action`]="{ item }">
          <v-icon @click="Edit(item.Id)" color="teal">mdi-pencil</v-icon>
        </template>
      </v-data-table>
    </v-col>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SistemaUsuariosIndex",
  data() {
    return {
      GridData: [],
      Header: [
        { text: "Id", value: "Id", sortable: false },
        { text: "Nome", value: "Nome", sortable: false },
        { text: "Usuário/Email", value: "Email", sortable: false },
        { text: "", value: "Action", sortable: false },
      ],
      RowsPerPageItems: [5, 10, 20, 30, 40, 50, 100, 200],
      Pagination: {
        page: 1,
        itemsPerPage: 100,
        pageCount: 1,
        itemsLength: 0,
        sortBy: ["Nome"],
        sortDesc: [false],
        Filtro: {
          Busca: "",
          Inativos: false,
          UserInterno: 1,
        },
      },
      showDialogCadastro: false,
      IsPermitted: 0,
      CanEdit: false,
      CanDelete: false,
      AllowAll: false,
      HideEdit: false,
      HideDelete: false,
    };
  },
  components: {},
  methods: {
    Edit(Id) {
      this.$router.push("/Sistema/Usuarios/Edit/".concat(Id));
    },
    Index() {
      axios.post("/api/Sistema/Usuarios/Index", this.Pagination).then((response) => {
        this.GridData = response.data.Data;
        this.Pagination = response.data.Pagination;
        this.LoadPermission();
      });
    },
    RowClass(item) {
      if (item.FlagAtivo == false) {
        return "rowdisabled";
      }
    },
    LoadPermission() {
      axios({ method: "get", data: null, url: "/api/Lista/GetAcesso" })
        .then((response) => {
          this.IsPermitted = response.data;
          this.HideEdit = Boolean(this.$functions.EditPermitted(this.IsPermitted));
          this.CanEdit = Boolean(this.$functions.EditPermitted(this.IsPermitted, 1));
          this.HideDelete = Boolean(this.$functions.DeletePermitted(this.IsPermitted));
          this.CanDelete = Boolean(this.$functions.DeletePermitted(this.IsPermitted, 1));
        })
        .catch((error) => {
          //
        });
    },
  },
  mounted: function () {
    this.Index();
    //this.$toast("Teste", { type: "error" });
  },
};
</script>
