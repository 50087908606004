<template>
  <div>
    <v-col cols="12" xl="8" offset-xl="2">
      <v-data-table class="elevation-1 row-pointer" mobile-breakpoint="1" :headers="Header" :items="GridData" :sort-desc.sync="Pagination.sortDesc" item-key="Id" :options.sync="Pagination" :server-items-length="Pagination.itemsLength" :footer-props="{ itemsPerPageOptions: RowsPerPageItems }" v-on:update:page="Index()" v-on:update:items-per-page="Index()" v-on:update:sort-by="Index()" v-on:update:sort-desc="Index()">
        <template v-slot:top>
          <v-toolbar dense flat>
            <v-col col="6">
              <v-text-field append-icon="mdi-magnify" hide-details dense outlined  label="Procurar" v-model="Pagination.Filtro.Busca" @change="Index()" @click:append="Index" ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-btn to="/Sistema/Controllers/Edit/0" color="primary" v-if="CanEdit">
              Nova
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>

          <br />
        </template>

        <template v-slot:[`item.FlagExcluido`]="{ item }">
          <v-icon v-if="item.FlagExcluido == 1" color="red">mdi-exclamation-thick</v-icon>
        </template>

        <template v-slot:[`item.FlagPublico`]="{ item }">
          <v-icon :color="item.FlagPublico == 1 ? 'green' : 'red'">mdi-check-circle</v-icon>
        </template>

        <template v-slot:[`item.Action`]="{ item }">
          <v-icon @click="Edit(item.Id)" color="teal">mdi-pencil</v-icon>
        </template>
      </v-data-table>
    </v-col>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SistemaControllersIndex",
  data() {
    return {
      GridData: [],
      Header: [
        { text: "Id", value: "Id", sortable: false },
        { text: "Nome", value: "Nome", sortable: false },
        { text: "Exclusão Manual", value: "FlagExcluido", sortable: false },
        { text: "Público", value: "FlagPublico", sortable: false },
        { text: "", value: "Action", sortable: false },
      ],
      RowsPerPageItems: [5, 10, 20, 30, 40, 50, 100, 200],
      Pagination: {
        page: 1,
        itemsPerPage: 100,
        pageCount: 1,
        itemsLength: 0,
        sortBy: ["Nome"],
        sortDesc: [false],
        Filtro: {
          Busca: "",
          Inativos: false,
        },
      },
      RotaControllers: [],
      TableController: [],
      ExistingControllers: [],
      IsPermitted: 0,
      CanEdit: false,
      CanDelete: false,
      AllowAll: false,
      HideEdit: false,
      HideDelete: false,
      showDialogCadastro: false,
    };
  },
  components: {},
  methods: {
    Edit(Id) {
      this.$router.push("/Sistema/Controllers/Edit/".concat(Id));
    },
    Index() {
      axios.post("/api/Sistema/Controllers/Index", this.Pagination).then((response) => {
        this.GridData = response.data.Data;
        this.Pagination = response.data.Pagination;
      });
    },
    async AddAllRoutes() {
      var TableControllersAxios = await axios.get("/api/Lista/ListaControllers");
      this.TableController = TableControllersAxios.data;
      var ExistingControllersAxios = await axios.get("/api/Lista/GetControllers");
      this.ExistingControllers = ExistingControllersAxios.data;
      // Vejo se existem controllers adicionais, e se tem alguma que tenha sido excluída
      let r;
      for (r of Object.values(this.ExistingControllers)) {
        let sId = TableControllersAxios.data.filter((x) => x.Nome == r.Nome);
        if (sId == 0) {
          axios({ method: "post", data: r, url: "/api/Sistema/Controllers/Save" });
        }
      }
      // Vejo se existem rotas que não existem mais e mostro a exclusão
      var TableControllersAxios = await axios.get("/api/Lista/ListaControllers");
      this.TableController = TableControllersAxios.data;
      let e;
      for (e of Object.values(this.TableController)) {
        let sId = ExistingControllersAxios.data.filter((x) => x.Nome == e.Nome);
        if (sId == 0) {
          e.FlagExcluido = 1;
          axios({ method: "post", data: e, url: "/api/Sistema/Controllers/Save" });
        }
      }
      this.Index();
    },
    LoadPermission() {
      axios({ method: "get", data: null, url: "/api/Lista/GetAcesso" })
        .then((response) => {
          this.IsPermitted = response.data;
          this.HideEdit = this.$functions.EditPermitted(this.IsPermitted);
          this.CanEdit = Boolean(this.$functions.EditPermitted(this.IsPermitted, 1));
          this.AllowAll = this.$functions.AllowPermitted();
          this.HideDelete = this.$functions.DeletePermitted(this.IsPermitted);
          this.CanDelete = Boolean(this.$functions.DeletePermitted(this.IsPermitted, 1));
        })
        .catch((error) => {
          //
        });
    },
  },
  mounted: function () {
    this.AddAllRoutes();
    this.LoadPermission();
    // this.Index();
  },
};
</script>
