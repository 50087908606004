<template>
  <v-div :style="HideEdit">
    <v-card>
      <loadingEdit v-if="this.$store.getters.showLoading"></loadingEdit>
      <template v-else>
        <v-app-bar color="primary" dense>
          <v-toolbar-title class="title white--text">Controle de Menu</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="Index()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>
        <br />
        <v-card-text class="pl-1 pr-1">
          <v-row>
            <v-col cols="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
              <v-row>
                <v-col cols="4" md="2" lg="2">
                  <v-text-field outlined dense hide-details label="Id" v-model="Model.Id" readonly></v-text-field>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="4" offset="0">
                  <v-switch hide-details inset label="Acesso Liberado para Logados" color="success" v-model="Model.AllUsers" :false-value="false" :true-value="true"></v-switch>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="4" offset="0">
                  <v-switch hide-details inset label="Separador" color="success" v-model="Model.Divider" :false-value="0" :true-value="1"></v-switch>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
              <v-text-field outlined dense hide-details label="Item Menu" v-model.trim="Model.ItemMenu"></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
              <v-text-field outlined dense hide-details label="Link para acesso" v-model.trim="Model.Link"></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
              <v-row>
                <v-col col="12" lg="2"><v-text-field outlined dense hide-details label="Id Menu Pai" v-model="Model.IdMenuPai"></v-text-field></v-col>
                <v-col col="1" lg="10"><v-autocomplete :items="MenuPaiList" item-text="ItemMenu" item-value="Id" v-model="Model.IdMenuPai" label="Menu Pai" dense outlined hide-details></v-autocomplete></v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
              <v-text-field outlined dense hide-details label="Sequência do Menu" v-model="Model.Seq"></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
              <v-text-field outlined dense hide-details label="Ícone de Menu" v-model.trim="Model.Icon"></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="5" offset="1">
              <v-card>
                <v-app-bar color="primary" dense>
                  <v-toolbar-title class="title white--text">Acesso Controllers</v-toolbar-title>
                </v-app-bar>
                <v-card-text class="pl-1 pr-1">
                  <v-row>
                    <v-col>
                      <v-data-table class="elevation-0 bordagrid" item-class="row-pointer" mobile-breakpoint="1" :headers="HeaderControllers" :items="Model.MenuControllers" item-key="IdPessoa" single-select hide-default-footer disable-pagination disabled-filtering>
                        <template v-slot:top>
                          <v-toolbar dense flat>
                            <v-spacer></v-spacer>
                            <v-autocomplete @click="NovaController = null" :items="Controllers" item-text="Nome" item-value="Id" v-model="NovaController" label="Incluir Controller" dense outlined hide-details></v-autocomplete>
                            <v-btn @click="AddController()" color="primary"><v-icon>mdi-plus</v-icon></v-btn>
                          </v-toolbar>
                          <hr />
                        </template>

                        <template v-slot:[`item.Nome`]="{ item }">
                          {{ GetController(item) }}
                        </template>

                        <template v-slot:[`item.Action`]="{ item }">
                          <v-icon @click.stop="DelController(item)" color="error">mdi-trash-can</v-icon>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="5">
              <v-card>
                <v-app-bar color="primary" dense>
                  <v-toolbar-title class="title white--text">Controle de Menu de Acesso</v-toolbar-title>
                </v-app-bar>
                <v-card-text class="pl-1 pr-1">
                  <v-row>
                    <v-col>
                      <v-data-table class="elevation-0 bordagrid" item-class="row-pointer" mobile-breakpoint="1" :headers="HeaderAcesso" :items="Model.Acessos" item-key="IdPessoa" single-select hide-default-footer disable-pagination disabled-filtering>
                        <template v-slot:top>
                          <v-toolbar dense flat>
                            <v-spacer></v-spacer>
                            <v-autocomplete @click="NovoGrupo = null" :items="Usuarios" item-text="Nome" item-value="Id" v-model="NovoUsuario" label="Incluir Usuário" dense outlined hide-details></v-autocomplete>
                            <v-autocomplete @click="NovoUsuario = null" :items="Grupos" item-text="Nome" item-value="Id" v-model="NovoGrupo" label="Incluir Grupo" dense outlined hide-details></v-autocomplete>
                            <v-btn @click="AddAcesso()" color="primary"><v-icon>mdi-plus</v-icon></v-btn>
                          </v-toolbar>
                          <hr />
                        </template>

                        <template v-slot:[`item.Acesso`]="{ item }">
                          <span v-if="item.IdUsuario">
                            <v-icon>mdi-account</v-icon>
                            {{ GetUsuario(item) }}
                          </span>
                          <span v-else>
                            <v-icon>mdi-account-group</v-icon>
                            {{ GetGrupo(item) }}
                          </span>
                        </template>

                        <template v-slot:[`item.ReadOnly`]="{ item }">
                          <span v-if="item.ReadOnly" class="red--text" @click.stop="ChangeAccess(item, 'ReadOnly')">ReadOnly</span>
                          <span v-else class="green--text" @click.stop="ChangeAccess(item, 'ReadOnly')">Read/Write</span>
                        </template>

                        <template v-slot:[`item.FlagIndex`]="{ item }">
                          <v-icon v-if="!item.FlagIndex" color="red" @click.stop="ChangeAccess(item, 'Index')">mdi-close-circle</v-icon>
                          <v-icon v-if="item.FlagIndex" color="green" @click.stop="ChangeAccess(item, 'Index')">mdi-check-circle</v-icon>
                        </template>

                        <template v-slot:[`item.FlagEdit`]="{ item }">
                          <v-icon v-if="!item.FlagEdit" color="red" @click.stop="ChangeAccess(item, 'Edit')">mdi-close-circle</v-icon>
                          <v-icon v-if="item.FlagEdit" color="green" @click.stop="ChangeAccess(item, 'Edit')">mdi-check-circle</v-icon>
                        </template>

                        <template v-slot:[`item.FlagDelete`]="{ item }">
                          <v-icon v-if="!item.FlagDelete" color="red" @click.stop="ChangeAccess(item, 'Delete')">mdi-close-circle</v-icon>
                          <v-icon v-if="item.FlagDelete" color="green" @click.stop="ChangeAccess(item, 'Delete')">mdi-check-circle</v-icon>
                        </template>

                        <template v-slot:[`item.Action`]="{ item }">
                          <v-icon @click.stop="DelAcesso(item)" color="error">mdi-trash-can</v-icon>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <br />
          <br />
          <v-row dense :style="this.AllowAll">
            <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
              <v-row>
                <v-col>
                  <v-btn block @click="ShowConfirmaExcluir = true" color="error" v-if="this.CanDelete && Model.Id != 0">Excluir Item de Menu</v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-col>
                  <v-btn block @click="Index()" color="warning">Cancelar</v-btn>
                </v-col>
                <v-col>
                  <v-btn block @click="ShowConfirmaSalvar = true" color="primary" v-if="this.CanEdit">Salvar</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <br />
        </v-card-text>
      </template>
    </v-card>

    <confirm Msg="Excluir?" :Show="ShowConfirmaExcluir" v-on:confirm="Delete(), (ShowConfirmaExcluir = false)" v-on:cancel="ShowConfirmaExcluir = false"></confirm>
    <confirm Msg="Salvar?" :Show="ShowConfirmaSalvar" v-on:confirm="Save(), (ShowConfirmaSalvar = false)" v-on:cancel="ShowConfirmaSalvar = false"></confirm>
    <messageshow :Title="ShowMessageTitle" :Msg="ShowMessageText" :Show="ShowAviso" v-on:confirm="ShowAviso = false" v-on:cancel="ShowAviso = false"></messageshow>
  </v-div>
</template>

<script>
import axios from "axios";

export default {
  name: "SistemaUsuarioEdit",
  data() {
    return {
      ShowDialog: true,
      Model: {},
      NovoUsuario: 0,
      NovaController: 0,
      NovoGrupo: 0,
      NovoReadOnly: true,
      Usuarios: [],
      Grupos: [],
      Controllers: [],
      ModelAcesso: [],
      MenuPai: [],
      MenuPaiList: [],
      ShowConfirmaExcluir: false,
      ShowConfirmaSalvar: false,
      ShowAviso: false,
      ShowMessageDialog: null,
      ShowMessageTitle: null,
      ShowMessageText: null,
      IsPermitted: 0,
      CanEdit: false,
      CanDelete: false,
      AllowAll: false,
      HideEdit: false,
      HideDelete: false,
      HeaderAcesso: [
        // { text: "Grupo", value: "IdGrupo", sortable: true },
        // { text: "Usuário", value: "IdUsuario", sortable: true },
        { text: "Acesso Usuário/Grupo", value: "Acesso", sortable: true },
        { text: "Acesso", value: "ReadOnly", sortable: true },
        { text: "Index", value: "FlagIndex", sortable: true },
        { text: "Edit", value: "FlagEdit", sortable: true },
        { text: "Delete", value: "FlagDelete", sortable: true },
        { text: "", value: "Action", sortable: false, width: "70px", align: "center" },
      ],
      HeaderControllers: [
        { text: "Controller", value: "Nome", sortable: true },
        { text: "", value: "Action", sortable: false, width: "70px", align: "center" },
      ],
    };
  },
  methods: {
    async Edit(Id) {

      return axios.get("/api/Sistema/Menus/Edit/".concat(Id)).then((response) => {
        let data = response.data.Data;
        if (data != null) {
          this.Model = data;
        }
      });
    },
    Save() {
      axios({ method: "post", data: this.Model, url: "/api/Sistema/Menus/Save" })
        .then((response) => {
          this.Index();
          this.$toast.success("Dados Salvos");
        })
        .catch((error) => {
          //
        });
    },
    Delete() {
      axios({ method: "delete", data: this.Model, url: "/api/Sistema/Menus/Delete" })
        .then((response) => {
          this.Index();
        })
        .catch((error) => {
          //
        });
    },
    Index() {
      this.$router.push("/Sistema/Menus/Index");
    },
    ChangeAccess(item, Acesso) {
      if (Acesso == "Index" && !item.ReadOnly) {
        item.FlagIndex = !item.FlagIndex;
      } else if (Acesso == "Edit" && !item.ReadOnly) {
        item.FlagEdit = !item.FlagEdit;
      } else if (Acesso == "Delete" && !item.ReadOnly) {
        item.FlagDelete = !item.FlagDelete;
      } else if (Acesso == "ReadOnly") {
        item.ReadOnly = !item.ReadOnly;
      }
      if (item.ReadOnly) {
        item.FlagIndex = true;
        item.FlagEdit = false;
        item.FlagDelete = false;
      }
      if (item.FlagDelete) {
        item.FlagEdit = true;
        item.FlagIndex = true;
      }
      if (item.FlagEdit) {
        item.FlagIndex = true;
      }
    },
    GetController(item) {
      let nome = "";
      try {
        nome = this.Controllers.filter((x) => x.Id == item.IdGrupoControllers)[0].Nome;
      } catch {}
      return nome;
    },
    GetUsuario(item) {
      let nome = "";
      try {
        nome = this.Usuarios.filter((x) => x.Id == item.IdUsuario)[0].Nome;
      } catch {}
      return nome;
    },
    GetGrupo(item) {
      let nome = "";
      try {
        nome = this.Grupos.filter((x) => x.Id == item.IdGrupo)[0].Nome;
      } catch {}
      return nome;
    },
    async LoadData(id) {
      await this.GetListas();
      await this.Edit(id);
    },
    async GetListas() {
      this.$store.commit("SET_LOADING", true);
      const instance = axios.create();
      
      const GetUsuarios = instance.get("/api/Lista/Usuarios");
      const GetGrupos = instance.get("/api/Lista/Grupos");
      const GetControllers = instance.get("/api/Lista/GrupoDeControllers");
      const GetMenuPai = instance.get("/api/Lista/SistemaMenus");
      const GetPermissoes = instance.get("/api/Lista/GetAcesso");

      return axios
        .all([GetUsuarios, GetGrupos, GetControllers, GetMenuPai, GetPermissoes])
        .then(
          axios.spread((...responses) => {
            
            responses[0].data.forEach((item) => {
              this.Usuarios.push(item);
            });

            responses[1].data.forEach((item) => {
              this.Grupos.push(item);
            });

            responses[2].data.forEach((item) => {
              this.Controllers.push(item);
            });

            //Carrega liste de MenuPai
            let lista =  responses[3].data;
            lista.push({ Id: 0, ItemMenu: "PRINCIPAL", IdMenuPai: null, Icon: null, Link: null, Seq: null, Divider: null });
            this.MenuPaiList = lista.sort((a, b) => (a.ItemMenu < b.ItemMenu ? -1 : 1));

            //Carrega Permissões
            this.IsPermitted = responses[4].data;
            this.HideEdit = this.$functions.EditPermitted(this.IsPermitted);
            this.CanEdit = Boolean(this.$functions.EditPermitted(this.IsPermitted, 1));
            this.AllowAll = this.$functions.AllowPermitted();
            this.HideDelete = this.$functions.DeletePermitted(this.IsPermitted);
            this.CanDelete = Boolean(this.$functions.DeletePermitted(this.IsPermitted, 1));
          })
        )
        .then((response) => {
          this.Grupos = this.Grupos.filter((x) => x.FlagGrupoDeController == false);
        })
        .catch((errors) => {});
    },
    DelAcesso(Item) {
      this.Model.Acessos.splice(this.Model.Acessos.indexOf(Item), 1);
    },
    AddAcesso() {
      if (!this.NovoGrupo && !this.NovoUsuario) {
        this.ShowMessageTitle = "Aviso";
        this.ShowMessageText = this.MessageInsertError;
        this.ShowAviso = true;
        return;
      }

      let acesso = this.Model.Acessos.find((x) => x.IdUsuario == this.NovoUsuario && x.IdMenu == this.Model.Id);
      if (acesso && this.NovoUsuario != null) {
        this.ShowMessageTitle = "Aviso";
        this.ShowMessageText = "Usuário já cadastrado";
        this.ShowAviso = true;
        return;
      }

      acesso = this.Model.Acessos.find((x) => x.IdGrupo == this.NovoGrupo && x.IdMenu == this.Model.Id);
      if (acesso && this.NovoGrupo != null) {
        this.ShowMessageTitle = "Aviso";
        this.ShowMessageText = "Grupo já cadastrado";
        this.ShowAviso = true;
        return;
      }

      this.Model.Acessos.push({
        Id: 0,
        IdMenu: this.Model.Id,
        IdGrupo: this.NovoGrupo,
        IdUsuario: this.NovoUsuario,
        ReadOnly: true,
        FlagIndex: true,
        FlagEdit: false,
        FlagDelete: false,
      });
      this.NovoGrupo = null;
      this.NovoUsuario = null;
      this.NovoReadOnly = true;
    },
    DelController(Item) {
      this.Model.MenuControllers.splice(this.Model.MenuControllers.indexOf(Item), 1);
    },
    AddController() {
      if (!this.NovaController) {
        this.ShowMessageTitle = "Aviso";
        this.ShowMessageText = this.MessageInsertError;
        this.ShowAviso = true;
        return;
      }

      let acesso = this.Model.MenuControllers.find((x) => x.IdMenuControllers == this.NovaController);
      if (acesso) {
        this.ShowMessageTitle = "Aviso";
        this.ShowMessageText = "Controller já cadastrada";
        this.ShowAviso = true;
        return;
      }

      this.Model.MenuControllers.push({
        Id: 0,
        IdMenu: this.Model.Id,
        IdGrupoControllers: this.NovaController,
      });
      this.NovaController = null;
    },
  },
  computed: {},
  mounted: function () {
    console.clear();
    let id = this.$route.params.id;
    if (id != null) {
      this.LoadData(id);
    } else {
      this.$router.push("/Sistema/SistemaMenus/Index");
    }
  },
};
</script>
