<template>
	<v-flex xs12 sm12 md12 class="mt-15 mb-15">
		<v-dialog persistent v-model="showDialog" @keydown.enter="Close()" @keydown.esc="Close()" width="420px">
			<v-card>
				<v-app-bar color="primary" dense>
					<v-toolbar-title class="white--text">
						{{Title}}
					</v-toolbar-title>
				</v-app-bar>
				<v-card-actions>
					<br/><br/>
					<v-spacer></v-spacer>
					<b>{{Msg}}</b>
					<v-spacer></v-spacer>
				</v-card-actions>	
				<v-card-actions>
					<br/><br/>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="Close()" width="35%">OK</v-btn>
					<v-spacer></v-spacer>
					<br/><br/><br/>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-flex>
</template>

<script>
export default {
	name: 'messageshow',
	props: ['Title','Msg', 'Show'],
	methods: {
		Close() {
			this.$emit('cancel')
		},
	},
	computed: {
		showDialog() {
			return this.Show
		}
	}
}
</script>