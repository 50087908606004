<template>
  <div>
    <v-data-table dense class="elevation-1 row-pointer" mobile-breakpoint="1" :headers="Cabecalho" :items="dados" :footer-props="{itemsPerPageOptions: rowsPerPageItems }">

      <template v-slot:top>
        <v-toolbar dense flat>
          <v-spacer></v-spacer>
          <v-btn to="/CadastroGeral/Status/Edit/0" color="primary">Novo <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.Action`]="{ item }">
        <v-icon @click="edit(item.ID)" color="teal">mdi-pencil</v-icon>
      </template>

    </v-data-table>

  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'Status',
  data() {
    return {
      dados: [],
      Cabecalho: [
        { text: "ID", value: "ID", sortable: false },
        { text: "Nome", value: "Nome", sortable: false },
        { text: "", value: "Action", sortable: false },
      ],
      rowsPerPageItems: [5, 10, 20, 30, 40, 50, 100, 200],
      pagination: {
        page: 1,
        itemsPerPage: 100,
        pageCount: 1,
        itemsLength: 0,
        sortBy: ["NM_NOME"],
        sortDesc: [false],
        Filtro: {
          NM_BUSCA: "",
          FL_ATIVO: "0"
        }
      },
      showDialogCadastro: false
    };
  },
  components: {

  },
  methods: {
    edit(ID) {
      this.$router.push("/CadastroGeral/Status/Edit/".concat(ID));
    }
  },
  mounted: function () {

    axios.post('/api/CadastroGeral/Status/Index', this.pagination)
      .then((response) => {
        this.dados = response.data.Data;
      });
  },
}
  </script>
