<template>
  <div :style="HideEdit">
    <v-card>
      <loadingEdit v-if="this.$store.getters.showLoading"></loadingEdit>
      <template v-else>
        <v-card-text class="pl-1 pr-1">
          <v-row dense>
            <v-col col="4" md="2" lg="2" offset="0" offset-md="2" offset-lg="2">
              <v-text-field outlined dense hide-details label="Id" v-model="Model.Id" readonly></v-text-field>
            </v-col>
            <v-col col="4" md="3" lg="1">
              <v-switch hide-details inset label="Ativo" color="primary" v-model="Model.FlagAtivo" :false-value="false" :true-value="true"></v-switch>
            </v-col>
            <v-col col="4" md="3" lg="2">
              <v-switch hide-details inset label="Grupo de Controller" color="primary" v-model="Model.FlagGrupoDeController" @change="TipoGrupoChange()" :false-value="false" :true-value="true"></v-switch>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
              <v-text-field outlined dense hide-details label="Nome" v-model="Model.Nome" ref="txtNome"></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col col="12" md="6" lg="6" offset="0" offset-md="3" offset-lg="3">
              <v-data-table dense class="elevation-1 row-pointer" mobile-breakpoint="1" :headers="computedHeader" :items="computedDataGrid" hide-default-footer hide-default-header disable-pagination disabled-filtering>
                <template v-slot:top>
                  <v-toolbar dense flat>
                    <v-toolbar-title>Membos do Grupo</v-toolbar-title>
                    <v-divider class="mx-1" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-autocomplete v-if="Model.FlagGrupoDeController" return-object :items="Controllers" item-text="Nome" item-value="Id" v-model="Membro" label="Controller" dense outlined hide-details></v-autocomplete>
                    <v-autocomplete v-else return-object :items="Usuarios" item-text="Nome" item-value="Id" v-model="Membro" label="Usuario" dense outlined hide-details></v-autocomplete>
                    <v-btn @click="AddMembro()" color="primary"><v-icon>mdi-plus</v-icon> </v-btn>
                  </v-toolbar>
                  <hr />
                </template>

                <template v-slot:item.Action="{ item }">
                  <v-icon @click="RemoveMembro(item)" color="error">mdi-trash-can</v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <v-row dense :style="this.AllowAll">
            <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
              <v-row>
                <v-col>
                  <v-btn block @click="ShowConfirmaExcluir = true" color="error" v-if="this.CanDelete && Model.Id != 0">Excluir Grupo</v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-col>
                  <v-btn block @click="Index()" color="warning">Cancelar</v-btn>
                </v-col>
                <v-col>
                  <v-btn block @click="ShowConfirmaSalvar = true" color="primary" v-if="this.CanEdit">Salvar</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </template>
    </v-card>

    <confirm Msg="Excluir?" :Show="ShowConfirmaExcluir" v-on:confirm="Delete(), (ShowConfirmaExcluir = false)" v-on:cancel="ShowConfirmaExcluir = false"></confirm>
    <confirm Msg="Salvar?" :Show="ShowConfirmaSalvar" v-on:confirm="Save(), (ShowConfirmaSalvar = false)" v-on:cancel="ShowConfirmaSalvar = false"></confirm>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "GruposEdit",
  data() {
    return {
      ShowDialog: true,
      Model: {},
      ShowConfirmaExcluir: false,
      ShowConfirmaSalvar: false,
      Usuarios: [],
      Controllers: [],
      Membro: null,
      Header: [
        { text: "Membro", value: "Usuario.Nome", sortable: false },
        { text: "Membro", value: "Controller.Nome", sortable: false },
        { text: "", value: "Action", sortable: false, width: "70px", align: "center" },
      ],
            IsPermitted: 0,
      CanEdit: false,
      CanDelete: false,
      AllowAll: false,
      HideEdit: false,
      HideDelete: false,
    };
  },
  methods: {
    Edit(Id) {
      axios.get("/api/Sistema/Grupos/Edit/".concat(Id)).then((response) => {
        let data = response.data.Data;
        if (data != null) {
          this.Model = data;
        }
      });
    },
    Save() {
      axios({ method: "post", data: this.Model, url: "/api/Sistema/Grupos/Save" })
        .then((response) => {
          this.Index();
          this.$toast.success("Dados Salvos");
        })
        .catch((error) => {
          //
        });
    },
    Delete() {
      axios({ method: "delete", data: this.Model, url: "/api/Sistema/Grupos/Delete" })
        .then((response) => {
          this.Index();
        })
        .catch((error) => {
          //
        });
    },
    Index() {
      this.$router.push("/Sistema/Grupos/Index");
    },
    GetListas() {
      const instance = axios.create();

      const GetUsuarios = instance.get("/api/Lista/Usuarios");
      const GetControllers = instance.get("/api/Lista/Controller");

      axios
        .all([GetUsuarios, GetControllers])
        .then(
          axios.spread((...responses) => {
            responses[0].data.forEach((item) => {
              this.Usuarios.push(item);
            });

            responses[1].data.forEach((item) => {
              this.Controllers.push(item);
            });
          })
        )
        .catch((errors) => {});
    },
    RemoveMembro(Item) {
      if (this.Model.FlagGrupoDeController) {
        this.Model.Controllers.splice(this.Model.Controllers.indexOf(Item), 1);
      } else {
        this.Model.Usuarios.splice(this.Model.Usuarios.indexOf(Item), 1);
      }
    },
    AddMembro() {
      if (!this.Membro) {
        return;
      }

      if (this.Model.FlagGrupoDeController) {
        let colab = this.Model.Controllers.find((x) => x.Controller.Id == this.Membro.Id);
        if (colab) {
          return;
        }

        this.Model.Controllers.push({ Id: 0, IdController: this.Membro.Id, IdGrupo: this.Model.Id, Controller: this.Membro });
      } else {
        let colab = this.Model.Usuarios.find((x) => x.Usuario.Id == this.Membro.Id);
        if (colab) {
          return;
        }

        this.Model.Usuarios.push({ Id: 0, IdSistemaUsuario: this.Membro.Id, IdGrupo: this.Model.Id, Usuario: this.Membro });
      }
    },
    TipoGrupoChange() {
      this.Membro = null;
    },
  },
  computed: {
    computedDataGrid() {
      if (this.Model.FlagGrupoDeController) {
        return this.Model.Controllers;
      } else {
        return this.Model.Usuarios;
      }
    },
    computedHeader() {
      if (this.Model.FlagGrupoDeController) {
        return this.Header.filter((item) => {
          return item.value != "Usuario.Nome";
        });
      } else {
        return this.Header.filter((item) => {
          return item.value != "Controller.Nome";
        });
      }
    },
  },
  mounted: function () {
    let id = this.$route.params.id;
    if (id != null) {
      this.GetListas();
      this.Edit(id);
      this.$refs.txtNome.focus();
    } else {
      this.$router.push("/Sistema/Grupos/Index");
    }
  },
};
</script>
