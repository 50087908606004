<template>
  <div>
    <v-card>
      <v-card-text class="pl-1 pr-1">
        <v-row dense>
          <v-col col="11" md="2" lg="2" offset="0" offset-md="2" offset-lg="2">
            <v-text-field outlined dense hide-details label="ID" v-model="Model.ID" readonly style="background-color: #d1d1d1"></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
            <v-text-field outlined dense hide-details label="Nome" v-model="Model.Nome" ref="txtNome"></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
            <v-row>
              <v-col>
                <v-btn block @click="ShowConfirmaExcluir = true" color="error" v-if="Model.ID != 0">Excluir Status</v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-col>
                <v-btn block @click="Index()" color="warning">Cancelar</v-btn>
              </v-col>
              <v-col>
                <v-btn block @click="ShowConfirmaSalvar = true" color="primary">Salvar</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <confirm Msg="Excluir?" :Show="ShowConfirmaExcluir" v-on:confirm="Delete(), (ShowConfirmaExcluir = false)" v-on:cancel="ShowConfirmaExcluir = false"></confirm>
    <confirm Msg="Salvar?" :Show="ShowConfirmaSalvar" v-on:confirm="Save(), (ShowConfirmaSalvar = false)" v-on:cancel="ShowConfirmaSalvar = false"></confirm>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "StatusEdit",
  data() {
    return {
      ShowDialog: true,
      Model: {},
      ShowConfirmaExcluir: false,
      ShowConfirmaSalvar: false,
    };
  },
  methods: {
    Edit(ID) {
      axios.get("/api/CadastroGeral/Status/Edit/".concat(ID)).then((response) => {
        let data = response.data.Data;
        if (data != null) {
          this.Model = data;
        }
      });
    },
    Save() {
      axios({ method: "post", data: this.Model, url: "/api/CadastroGeral/Status/Save" })
        .then((response) => {
          this.Index();
        })
        .catch((error) => {
          //
        });
    },
    Delete() {
      axios({ method: "delete", data: this.Model, url: "/api/CadastroGeral/Status/Delete" })
        .then((response) => {
          this.Index();
        })
        .catch((error) => {
          //
        });
    },
    Index() {
      this.$router.push("/CadastroGeral/Status/Index");
    },
  },
  mounted: function () {
    let id = this.$route.params.id;
    if (id != null) {
      this.$refs.txtNome.focus();
      this.Edit(id);
    } else {
      this.$router.push("/CadastroGeral/Status/Index");
    }
  },
};
</script>
